import React from "react";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import SectionTitle from "../../ui_base/SectionTitle";
import { Button, Checkbox, Container, FlexboxGrid, IconButton, Input, InputGroup, Panel, RadioTile, RadioTileGroup, SelectPicker, Table, Tag, Text, useMediaQuery } from "rsuite";
import {TagDefault} from '../../../utils/tag';
import SearchIcon from '@rsuite/icons/Search';
import Column from "rsuite/esm/Table/TableColumn";
import { Cell, HeaderCell } from "rsuite-table";
import EyeRoundIcon from '@rsuite/icons/EyeRound';
import { IconDanger, IconSuccess, IconWarning } from "../../../utils/icons";
import chatgpt_icon from '../../../assets/chatgpt-icon.png';
import claude_icon from '../../../assets/claude-ai-icon.png';
import gemini_icon from '../../../assets/google-gemini-icon.png';

const ConfigProjectSolution = () => {
    const location = useLocation();
    const [projectSolution, setProjectSolution] = useState();
    const {id, solutionId} = location.state || {};
    const [isInline] = useMediaQuery('sm');
    const [LLM, setLLM] = useState('');
    const [personality, setPersonality] = useState('');
    const [strategicContext, setStrategicContext] = useState("");
    const [projectContext, setProjectContext] = useState("");
    const [companyContext, setCompanyContext] = useState("");
    const [industryContext, setIndustryContext] = useState("");
    const [competitiveContext, setCompetitiveContext] = useState("");

    useEffect(()=>{
        const getProjectSolution = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProjectSolution(data);
            if(data.ai_settings){
                setLLM(data.ai_settings.llm);
                setPersonality(data.ai_settings.personality);
                setStrategicContext(data.ai_settings.strategic_context);
                setProjectContext(data.ai_settings.project_context);
                setCompanyContext(data.ai_settings.company_context);
                setIndustryContext(data.ai_settings.industry_context);
                setCompetitiveContext(data.ai_settings.competitive_context);
            }
        }
        getProjectSolution();
    },[id, solutionId]);

    const handleManage = (id) =>{

    }

    const handleUpdateAISettings = () =>{
        const payload = {
            llm: LLM,
            personality,
            strategic_context: strategicContext,
            project_context: projectContext,
            company_context: companyContext,
            industry_context: industryContext,
            competitive_context: competitiveContext,
        }
        console.log(payload);
    }

    return (
        <Container style={{ margin: "20px 0"}}>
            {
                projectSolution && <>
                    <Panel>
                        <FlexboxGrid justify="space-between" style={{ marginBottom: "20px"}}>
                            <FlexboxGrid.Item colspan={6}><SectionTitle>Configure {projectSolution && projectSolution.solution.name}</SectionTitle></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>{(projectSolution.project.status==='active')?<Tag color="green">Project is active</Tag>:<Tag color="red">Project is inactive</Tag>}</FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={id} size="lg" data={[{key: 1, label: projectSolution.project.name, value: projectSolution.project.id}]} readOnly/></FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>{projectSolution.solution.name} settings</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Select drivers</h5>} collapsible bordered defaultExpanded>
                        <Panel bordered>
                            <FlexboxGrid justify="space-between" style={{ margin: "20px 0"}}>
                                <FlexboxGrid.Item colspan={4}><b>Available drivers:</b> {projectSolution.solution.drivers.length}</FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}><TagDefault label={`${projectSolution.drivers.length} drivers selected`} textTransform="none"/></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={4}><InputGroup><InputGroup.Button><SearchIcon /></InputGroup.Button><Input placeholder="Find driver"/></InputGroup></FlexboxGrid.Item>
                            </FlexboxGrid>
                            <Table data={projectSolution.solution.drivers} height={400} autoHeight rowHeight={60}>
                                <Column flexGrow={2}>
                                    <HeaderCell><Checkbox color="orange">Select all/none</Checkbox></HeaderCell>
                                    <Cell>{rowData=><Checkbox color="violet" checked={!!projectSolution.drivers.find(e=>e.id===rowData.id)}>{rowData.name}</Checkbox>}</Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell>Source</HeaderCell>
                                    <Cell>{rowData=>rowData.source}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Required driver</HeaderCell>
                                    <Cell>{rowData=><Checkbox color="violet" checked={projectSolution.drivers.find(e => e.id === rowData.id)?.is_required}></Checkbox>}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Last Update</HeaderCell>
                                    <Cell>{rowData=>new Date(rowData.updated_at).toLocaleDateString()}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Observations</HeaderCell>
                                    <Cell>{rowData=>rowData.observations}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>Quality check</HeaderCell>
                                    <Cell>{rowData=>{
                                        if(rowData.quality==='good'){
                                            return (<IconSuccess/>)
                                        }
                                        else if(rowData.quality==='check'){
                                            return (<IconWarning/>)
                                        }
                                        else if(rowData.quality==='poor'){
                                            return (<IconDanger/>)
                                        }
                                    }}</Cell>
                                </Column>
                                <Column flexGrow={1} align="center">
                                    <HeaderCell>View</HeaderCell>
                                    <Cell>{rowData=><IconButton appearance="subtle" icon={<EyeRoundIcon/>} onClick={()=>handleManage(rowData.id)}/>}</Cell>
                                </Column>
                            </Table>
                        </Panel>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Time series data prep</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Scenario settings</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>Custom report settings</h5>} collapsible bordered>
                    </Panel>
                    <Panel style={{ marginBottom: "20px"}} header={<h5 style={{ color: "#6941c6" }}>AI+ settings</h5>} collapsible bordered defaultExpanded>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginBottom: "5px"}}>
                                <Text weight="bold">Select LLM</Text>
                            </div>
                            <RadioTileGroup defaultValue={LLM} inline={isInline}>
                                <RadioTile value="chatgpt_4" label="ChatGPT 4.0" icon={<img src={chatgpt_icon} alt="chatgpt icon"/>} onClick={()=>setLLM("chatgpt_4")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                                <RadioTile value="claude" label="Claude" icon={<img src={claude_icon} alt="claude icon"/>} onClick={()=>setLLM("claude")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                                <RadioTile value="gemini" label="Gemini" icon={<img src={gemini_icon} alt="gemini icon"/>} onClick={()=>setLLM("gemini")} style={{ width: "33%"}}>
                                    This is what it's good for and how you can use it.
                                </RadioTile>
                            </RadioTileGroup>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Select personality</Text>
                            </div>
                            <RadioTileGroup defaultValue={personality} inline={isInline}>
                                <RadioTile value="brief" label="Brief and to-the-point" onClick={()=>setPersonality("brief")} style={{ width: "33%"}}>
                                    Professional and efficient. Prefers bullet points and easy-to-digest summaries.
                                </RadioTile>
                                <RadioTile value="analytical" label="Analytical and comprehensive" onClick={()=>setPersonality("analytical")} style={{ width: "33%"}}>
                                    Prefers full paragraphs. Emphasizes reasoning and explanations.
                                </RadioTile>
                                <RadioTile value="entrepreneurial" label="Analytical and entrepreneurial" onClick={()=>setPersonality("entrepreneurial")} style={{ width: "33%"}}>
                                    Combines full paragraphs and bullet points. Emphasizes trends, opportunities and ideas.
                                </RadioTile>
                            </RadioTileGroup>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Strategic context: What do you want to achieve and how?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={strategicContext} onChange={(value)=>setStrategicContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Project context: Tell us</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={projectContext} onChange={(value)=>setProjectContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Company context: Tell us</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={companyContext} onChange={(value)=>setCompanyContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Industry context: What are the key features, drivers and trends of the industry?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={industryContext} onChange={(value)=>setIndustryContext(value)}/>
                        </div>
                        <div style={{ margin: "30px 0"}}>
                            <div style={{ marginTop: "20px", marginBottom: "5px" }}>
                                <Text weight="bold">Competitive context: Who and what do you compete against, and what is the current market situation?</Text>
                            </div>
                            <Input as="textarea" rows={3} placeholder="Enter content" value={competitiveContext} onChange={(value)=>setCompetitiveContext(value)}/>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button appearance="primary" color="violet" onClick={handleUpdateAISettings}>Save settings</Button>
                        </div>
                    </Panel>
                </>
            }
        </Container>
    );
}

export default ConfigProjectSolution;