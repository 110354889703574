import React from "react";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect } from "react";
import { Message } from "rsuite";

const Notification = ({ variant, message, duration, onClose }) => {
    useEffect(() => {
      const timer = setTimeout(() => {
        onClose();
      }, duration);
  
      return () => clearTimeout(timer);
    }, [duration, onClose]);
  
    return (
        <div className="flash-message">
            <Message type={variant}>
                <FontAwesomeIcon icon={faExclamationTriangle}/> {message}
            </Message>
        </div>
    );
  }

export default Notification;