import React, { useEffect, useState } from "react";
import { Button, Table } from "rsuite";
import GearIcon from '@rsuite/icons/Gear';
import { useLocation, useNavigate } from "react-router-dom";
import { TagSuccess, TagWarning } from "../../../utils/tag";

const { Column, HeaderCell, Cell } = Table;
const ClientProjects = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = location.state || {};
    const [clientProjects, setClientProjects] = useState([]);
    
    useEffect(()=>{
        const getClientProjects = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${id}/projects`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setClientProjects(data);
        }
        getClientProjects();
    },[id]);

    const handleManage = (id) => {
        navigate('/projects/'+id, {state: {id, projects: clientProjects.map(e=> {return {"id": e.id, "name": e.name}})}});
    }

    return (
        <div>
            <Table data={clientProjects} height={400} autoHeight bordered rowHeight={50}>
                <Column sortable flexGrow={2}>
                    <HeaderCell>Project name</HeaderCell>
                    <Cell>{rowData=>rowData.name}</Cell>
                </Column>
                <Column sortable flexGrow={2}>
                    <HeaderCell>Client</HeaderCell>
                    <Cell>{rowData=>rowData.client}</Cell>
                </Column>
                <Column sortable flexGrow={1}>
                    <HeaderCell>Created</HeaderCell>
                    <Cell>{rowData=>new Date(rowData.created_at).toLocaleDateString()}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Solutions</HeaderCell>
                    <Cell>{rowData=>rowData.id}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Status</HeaderCell>
                    <Cell>{rowData=>(rowData.status==="active")?<TagSuccess label={rowData.status}/>:<TagWarning label={rowData.status}/>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>KAM</HeaderCell>
                    <Cell>{rowData=>rowData.kam}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell></HeaderCell>
                    <Cell>{rowData=><Button size="sm" appearance="ghost" startIcon={<GearIcon/>} onClick={()=>handleManage(rowData.id)}>Manage</Button>}</Cell>
                </Column>
            </Table>
        </div>
    );
}
export default ClientProjects;
