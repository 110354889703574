import React from 'react';
import { Text, Panel } from 'rsuite';

/**
 * My Comments component
 * User comments and notes section
 */
const MyComments = () => {
    return (
        <div>
            <Text>This is the my comments section</Text>
        </div>
    );
};

export default MyComments;