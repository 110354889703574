
import React from 'react';
import { Text, Panel } from 'rsuite';

/**
 * Driver Importance component
 * Displays the relative importance of different drivers
 */
const DriverImportance = () => {
    return (
        <div>
            <Text>This is the driver section</Text>
            
        </div>
    );
};

export default DriverImportance;