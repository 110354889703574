import React from "react";
import { Button, Container, FlexboxGrid, IconButton, Input, InputGroup, Pagination, Panel, SelectPicker, Stack, Table, Text } from "rsuite";
import { useEffect, useState } from "react";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import SearchIcon from '@rsuite/icons/Search';
import GearIcon from '@rsuite/icons/Gear';
import { useNavigate } from "react-router-dom";
import {TagSuccess, TagDefault} from "../../utils/tag";
import {TagWarning} from "../../utils/tag";
import CreateClient from "./Create";
import SectionTitle from "../ui_base/SectionTitle";

const { Column, HeaderCell, Cell } = Table;

const ClientList = () => {
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [clients, setClients] = useState([]);

    const [showCreateClient, setShowCreateClient] = useState(false);
    const [activePage, setActivePage] = useState(1);
    const [refresh, setRefresh] = useState(new Date().getTime());

    useEffect(()=>{
        const getClients = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/?page=${activePage}&limit=${5}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setTotal(res.total);
            setActivePage(res.page);
            setClients(res.data);
        }
        getClients();
    }, [activePage, refresh]);

    // const getData = () => {
    //     if (sortColumn && sortType) {
    //         return data.sort((a, b) => {
    //             let x = a[sortColumn];
    //             let y = b[sortColumn];
    //             if (typeof x === 'string') {
    //                 x = x.charCodeAt();
    //             }
    //             if (typeof y === 'string') {
    //                 y = y.charCodeAt();
    //             }
    //             if (sortType === 'asc') {
    //                 return x - y;
    //             } else {
    //                 return y - x;
    //             }
    //         });
    //     }
    //     return data;
    // };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const getRowHeight = (rowData) => {
        const baseHeight = 50;
        const additionalHeightPerProject = 25;
        if (!rowData || !Array.isArray(rowData.projects)) {
            return baseHeight;
        }
        return baseHeight + rowData.projects.length * additionalHeightPerProject;
    };

    const handleManage = (id) => {
        navigate('/clients/'+id, {state: {id, clients: clients.map(e=> {return {"id": e.id, "name": e.name}})}});
    }

    return (
        <Container style={{ margin: "0 10px"}}>
            {
                clients && <>
                    <Panel>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item colspan={4}><SectionTitle>Manage Clients</SectionTitle></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={0} size="lg" data={clients.map((client, i)=>{ return {key:i+1, label: client.name, value: client.id} })} onChange={handleManage} style={{ width: 250 }}/></FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                    <Panel className="m-20" bordered>
                        <Panel className="mb-20" bordered style={{ backgroundColor: "#f9f5ff"}}>
                            <Stack justifyContent="space-between">
                                <span><Text weight="bold" size="xxl">Create new client</Text></span>
                                <IconButton icon={showCreateClient?<MinusRoundIcon/>:<AddOutlineIcon/>} color="violet" appearance="ghost" onClick={()=>setShowCreateClient(!showCreateClient)} circle></IconButton>
                            </Stack>
                            {
                                showCreateClient && <CreateClient setRefresh={setRefresh} setShowCreateClient={setShowCreateClient}/>
                            }
                        </Panel>
                        <Panel bordered>
                            <FlexboxGrid className="mb-20" justify="space-between">
                                <FlexboxGrid.Item colspan={3}><Text weight="bold">Total clients: {total}</Text></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={3}><TagDefault label="5 active" textTransform="none"/><TagDefault label="1 inactive" textTransform="none"/></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={6}><InputGroup><InputGroup.Button><SearchIcon /></InputGroup.Button><Input placeholder="Find client"/></InputGroup></FlexboxGrid.Item>
                            </FlexboxGrid>
                            <Table
                                height={400} 
                                autoHeight
                                data={clients}
                                sortColumn={sortColumn}
                                sortType={sortType}
                                onSortColumn={handleSortColumn}
                                loading={loading}
                                rowHeight={(rowData) => getRowHeight(rowData)} 
                                >
                                <Column fixed sortable flexGrow={1}>
                                    <HeaderCell>Client name</HeaderCell>
                                    <Cell dataKey="name"/>
                                </Column>

                                <Column fixed sortable flexGrow={1}>
                                    <HeaderCell>Contact</HeaderCell>
                                    <Cell>{rowData=>rowData.contact}</Cell>
                                </Column>

                                <Column flexGrow={1}>
                                    <HeaderCell>KAM</HeaderCell>
                                    <Cell>{rowData=>rowData.kam}</Cell>
                                </Column>

                                <Column sortable flexGrow={1}>
                                    <HeaderCell>Created</HeaderCell>
                                    <Cell>{rowData=>new Date(rowData.created_at).toLocaleDateString()}</Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell>Projects</HeaderCell>
                                    <Cell>
                                        {(rowData) => {if(rowData.projects && rowData.projects.length > 0){
                                            return (<div style={{ whiteSpace: 'pre-wrap' }}>
                                                {rowData?.projects.map(e=>e).join('\n')}
                                            </div>)
                                        }}}
                                    </Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell>Status</HeaderCell>
                                    <Cell>{rowData => (rowData.is_active)?<TagSuccess label="Active"/>:<TagWarning label="Inactive"/>}</Cell>
                                </Column>
                                <Column flexGrow={1}>
                                    <HeaderCell></HeaderCell>
                                    <Cell>{rowData =><Button size="sm" onClick={() => handleManage(rowData.id)} appearance="ghost" startIcon={<GearIcon/>}>Manage</Button>}</Cell>
                                </Column>
                            </Table>
                            <Panel>
                                <Pagination
                                    prev
                                    last
                                    next
                                    first
                                    size="sm"
                                    total={total}
                                    limit={5}
                                    activePage={activePage}
                                    onChangePage={setActivePage}
                                />
                            </Panel>
                        </Panel>
                    </Panel>
                </>
            }
        </Container>
    )
}

export default ClientList;