import { useState } from "react";
import {useAuth} from '../context/AuthContext';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignIn } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button, Container, Form, Message, Panel, Text } from "rsuite";

const Login = ()=>{
    const { login, error, isLoading } = useAuth();
    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });
    const navigate = useNavigate();

    const handleSubmit = async()=>{
        const isLoggedIn = await login(formData);
        if (isLoggedIn) {
            navigate('/dashboard'); 
        }
    }
    return (
        <Container>
            <Panel bordered className="panel-signup" header={<Text className="title">Login - Opinion Samtidsdata</Text>}>
                { error && <Message type="error">{error}</Message> }
                <Form method="POST" onSubmit={handleSubmit} fluid>
                    <Form.Group controlId="username">
                        <Form.ControlLabel>Username</Form.ControlLabel>
                        <Form.Control type="text" name="username" onChange={(value)=>setFormData({ ...formData, username: value })} required/>
                    </Form.Group>
                    <Form.Group controlId="password">
                        <Form.ControlLabel>Password</Form.ControlLabel>
                        <Form.Control type="password" name="password" onChange={(value)=>setFormData({ ...formData, password: value })} required/>
                    </Form.Group>
                    <Button type="submit" className="custom-button" startIcon={<FontAwesomeIcon icon={faSignIn}/>} disabled={isLoading}>{isLoading ? 'Logging in...' : 'Login'}</Button>
                </Form>
            </Panel>
        </Container>
    );
}
export default Login;