import React from "react";
import { Container, FlexboxGrid, Panel, SelectPicker, Tag } from "rsuite";
import ClientSettings from "./Settings";
import DeactivateClient from "./Deactivate";
import Users from "./Users";
import ClientProjects from "./Projects";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SectionTitle from "../../ui_base/SectionTitle";

const ConfigClient = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const {id, clients} = location.state || {};
    const [client, setClient] = useState(null);

    useEffect(()=>{
        const getClient = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${id}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setClient(data);
        }
        getClient();
    },[id]);

    const handleManage = (id) => {
        navigate('/clients/'+id, {state: {id, clients: clients.map(e=> {return {"id": e.id, "name": e.name}})}});
    }

    return (
        <Container style={{ margin: "0px 10px"}}>
            {
                client && <Panel>
                    <FlexboxGrid justify="space-between" style={{ marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={4}><SectionTitle>Manage Clients</SectionTitle></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>{(client.is_active)?<Tag color="green">Client is active</Tag>:<Tag color="red">Client is inactive</Tag>}</FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={id} size="lg" data={clients.map((client, i)=>{ return {key:i+1, label: client.name, value: client.id} })} onChange={handleManage} style={{ width: 250 }}/></FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Client settings</h5>} className="mb-20" collapsible bordered defaultExpanded><ClientSettings client={client}/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Users</h5>} className="mb-20" collapsible bordered><Users/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Projects</h5>} className="mb-20" collapsible bordered><ClientProjects/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Change log</h5>} className="mb-20" collapsible bordered></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Deactivate and delete</h5>} collapsible bordered><DeactivateClient/></Panel>
                </Panel>
            }
        </Container>
    )
}

export default ConfigClient;