import styled from '@emotion/styled';
import theme from '../../styles/theme';

const StandardButton = styled.button`
  background-color: ${theme.colors.primary};
  color: ${theme.colors.surface};
  border-radius: 0.5rem;
  font-family: Inter;
  font-weight: 500;
  font-size: 0.8rem;
  padding: 0.6rem;
  border: none;
  cursor: pointer;
  width: auto;
  &:hover {
    opacity: 0.8;
  }
`;

export default StandardButton;