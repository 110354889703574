import React, { useRef, useLayoutEffect, useState } from "react";
import { useSpring, animated } from "@react-spring/web";
import { PanelStyle } from "../styles/PanelStyle";

const Panel = ({ children }) => {
  const contentRef = useRef(); // Ref to measure the height of the content
  const [measuredHeight, setMeasuredHeight] = useState(0); // Keep track of measured height

  const [style, api] = useSpring(() => ({
    height: 0, // Start collapsed
    config: { tension: 200, friction: 20 }, // Animation settings
  }));

  useLayoutEffect(() => {
    const timeout = setTimeout(() => {
      if (contentRef.current) {
        const contentElement = contentRef.current;
    
        // Measure bounding height (content + padding + border)
        let totalHeight = contentElement.getBoundingClientRect().height;
    
        // Iterate over children to calculate their margins
        Array.from(contentElement.children).forEach((child) => {
          const computedStyle = window.getComputedStyle(child);
    
          // Add child margins to the total height
          const marginTop = parseFloat(computedStyle.marginTop) || 0;
          const marginBottom = parseFloat(computedStyle.marginBottom) || 0;
          totalHeight += marginTop + marginBottom;
        });
    
        // Start the animation with the calculated total height
        api.start({ height: totalHeight });
      }
    }, 0); // Small delay to allow layout stabilization
  
    return () => clearTimeout(timeout); // Cleanup timeout on unmount
  }, [children, api]);

  return (
    <animated.div css={PanelStyle} style={{ overflow: "hidden", ...style }}>
      <div ref={contentRef}>{children}</div>
    </animated.div>
  );
};

export default Panel;