import React, { useState, useRef, useEffect, forwardRef } from 'react';
import styled from '@emotion/styled';
import { dropdownContainer, dropdownMenu, dropdownItem } from '../styles/DropdownButtonStyles';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';
import { Link } from 'react-router-dom';

const Button = styled.button`
    font-family: Inter, sans-serif;
    line-height: 1rem;
    font-size: 0.8rem;
    text-transform: uppercase;
    font-weight: 600;
    color: #344054;
    letter-spacing: .02rem;
    padding: 0.4rem 0.5rem 0.35rem 0.9rem;
    border: 1px solid #e0e0e0;
    border-radius: 0.5rem;
    background: white;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    transition: box-shadow 0.3s ease;

    &:hover {
        background-color: rgba(0,0,0,0.03);
    }

    &.open {
        box-shadow: 0 0 0 4px rgba(0, 0, 0, 0.06); /* Outer glow with hard edge */
    }
`;

const StyledArrowDownLineIcon = styled(ArrowDownLineIcon)`
    font-size: 1.2rem;
    position: relative;
    top: -0.13rem;
`;

const StyledArrowUpLineIcon = styled(ArrowUpLineIcon)`
    font-size: 1.2rem;
    position: relative;
    top: -0.2rem;
`;

const MenuItem = forwardRef(({ children, setIsOpen, ...props }, ref) => (
    <Link css={dropdownItem} ref={ref} {...props} onClick={() => setIsOpen(false)}>
        {children}
    </Link>
));

const DropdownButton = ({ title, children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    const handleToggle = () => setIsOpen(!isOpen);

    return (
        <div css={dropdownContainer} ref={dropdownRef}>
            <Button onClick={handleToggle} className={isOpen ? 'open' : ''}>
                {title} {isOpen ? <StyledArrowUpLineIcon /> : <StyledArrowDownLineIcon />}
            </Button>
            <div css={dropdownMenu} className={isOpen ? 'open' : ''}>
                {React.Children.map(children, child => 
                    child ? React.cloneElement(child, { setIsOpen }) : null
                )}
            </div>
        </div>
    );
};

export default DropdownButton;
export { DropdownButton, MenuItem };