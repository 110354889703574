import { css } from '@emotion/react';

export const dropdownContainer = css`
    position: relative;
    display: inline-block;
    margin-right: 1rem;
`;

export const dropdownMenu = css`
    position: absolute;
    top: 100%;
    right: 0;
    min-width: 200px;
    background: white;
    border-radius: 0.6rem;
    box-shadow: 0 2px 8px rgba(0,0,0,0.15);
    padding: 0.4rem 0;
    margin-top: 0.5rem;
    z-index: 1000;
    display: none;
    flex-direction: column;
    &.open {
        display: flex;
    }
`;

export const dropdownItem = css`
    font-family: Inter, sans-serif;
    color: #344054;
    padding: 0.5rem 1rem;
    font-weight: 500;
    cursor: pointer;
    

    &:hover {
        background-color: #F9F5FF;
        //removing default <a> tag css styling
        text-decoration: none;
        
    }
`;

export const button = css`
    // ...existing styles...
    .arrow {
        margin-left: 0.5rem;
    }
`;