import { css } from '@emotion/react';
import profileSymbol from '../../assets/icons/profile.svg';
import CircleButton from './CircleButtonStyle';
import theme from '../../styles/theme';
const AvatarIconStyle = css`
    ${CircleButton}


    background-image: url(${profileSymbol});
    background-size: 40%;
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${theme.colors.primaryVeryLight};
    
`;

export default AvatarIconStyle;
