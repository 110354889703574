import { Button, Container, FlexboxGrid, IconButton, Input, InputGroup, Panel, SelectPicker, Stack, Table, Text } from "rsuite";
import { useEffect, useState } from "react";
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import SearchIcon from '@rsuite/icons/Search';
import GearIcon from '@rsuite/icons/Gear';
import { useNavigate } from "react-router-dom";
import {TagSuccess, TagWarning, TagDefault} from '../../utils/tag';
import SectionTitle from "../ui_base/SectionTitle";
import CreateProject from "./Create";

const { Column, HeaderCell, Cell } = Table;

const ProjectList = () => {
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [projects, setProjects] = useState([]);
    const [showCreateProject, setShowCreateProject] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());

    useEffect(()=>{
        const getProjects = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/?limit=100`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setTotal(res.total);
            setProjects(res.data);
        }
        getProjects();
    },[refresh]);

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const handleManage = (id) => {
        navigate('/projects/'+id, {state: {id, projects: projects.map(e=> {return {"id": e.id, "name": e.name}})}});
    }

    const getRowHeight = (rowData) => {
        const baseHeight = 50;
        const additionalHeightPerProject = 25;
        if (!rowData || !Array.isArray(rowData.projects)) {
            return baseHeight;
        }
        return baseHeight + rowData.projects.length * additionalHeightPerProject;
    };

    return (
        <Container style={{ margin: "0 10px"}}>
            <Panel>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item colspan={6}><SectionTitle>Manage Projects</SectionTitle></FlexboxGrid.Item>
                    <FlexboxGrid.Item colspan={6} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={0} size="lg" data={projects.map((project, i)=>{ return {key:i+1, label: project.name, value: project.id} })} onChange={handleManage} style={{ width: 250 }}/></FlexboxGrid.Item>
                </FlexboxGrid>
            </Panel>
            <Panel className="m-20" bordered>
                <Panel className="mb-20" bordered style={{ backgroundColor: "#f9f5ff"}}>
                    <Stack justifyContent="space-between">
                        <span><Text weight="bold" size="xxl">Create new project</Text></span>
                        <IconButton icon={showCreateProject?<MinusRoundIcon/>:<AddOutlineIcon/>} color="violet" appearance="ghost" onClick={()=>setShowCreateProject(!showCreateProject)} circle></IconButton>
                    </Stack>
                    {
                        showCreateProject && <CreateProject setRefresh={setRefresh} setShowCreateProject={setShowCreateProject}/>
                    }
                </Panel>
                <Panel>
                    <FlexboxGrid className="mb-20" justify="space-between">
                        <FlexboxGrid.Item colspan={3}><Text weight="bold">Total projects: {total}</Text></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={3}><TagDefault label="5 active" textTransform="none"/><TagDefault label="1 inactive" textTransform="none"/></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={6}><InputGroup><InputGroup.Button><SearchIcon /></InputGroup.Button><Input placeholder="Find project"/></InputGroup></FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Table
                        height={420}
                        autoHeight
                        data={projects}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        loading={loading}
                        rowHeight={(rowData) => getRowHeight(rowData)} 
                        >
                        <Column fixed sortable flexGrow={2}>
                            <HeaderCell>Project name</HeaderCell>
                            <Cell dataKey="name" />
                        </Column>

                        <Column fixed sortable flexGrow={1}>
                            <HeaderCell>Client</HeaderCell>
                            <Cell>{rowData => rowData.client}</Cell>
                        </Column>

                        <Column sortable flexGrow={1}>
                            <HeaderCell>Created</HeaderCell>
                            <Cell>{rowData => new Date(rowData.created_at).toLocaleDateString()}</Cell>
                        </Column>

                        <Column sortable flexGrow={2}>
                            <HeaderCell>Solutions</HeaderCell>
                            <Cell>
                                {rowData=>rowData.solutions.map((e, index) => (
                                    <TagDefault key={index} label={e} />
                                ))}
                            </Cell>
                        </Column>

                        <Column sortable flexGrow={1}>
                            <HeaderCell>Status</HeaderCell>
                            <Cell>{rowData => (rowData.status==="active")?<TagSuccess label={rowData.status}/>:<TagWarning label={rowData.status}/>}</Cell>
                        </Column>

                        <Column sortable flexGrow={1}>
                            <HeaderCell>KAM</HeaderCell>
                            <Cell>{rowData => rowData.kam}</Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Action</HeaderCell>
                            <Cell>{rowData =><Button size="sm" onClick={() => handleManage(rowData.id)} appearance="ghost" startIcon={<GearIcon/>}>Manage</Button>}</Cell>
                        </Column>
                    </Table>
                </Panel>
            </Panel>
        </Container>
    )
}

export default ProjectList;