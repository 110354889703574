import React from "react";
import { Container, FlexboxGrid, Panel, SelectPicker, Tag } from "rsuite";
import Settings from "./Settings";
import Solutions from "./Solutions";
import Users from "./Users";
import Deactivate from "./Deactivate";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import SectionTitle from "../../ui_base/SectionTitle";

const ConfigProject = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [project, setProject] = useState();
    const {id, projects} = location.state || {};

    useEffect(()=>{
        const getProject = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setProject(data);
        }
        getProject();
    },[id]);

    const handleManage = (id) => {
        navigate('/projects/'+id, {state: {id, projects: projects.map(e=> {return {"id": e.id, "name": e.name}})}});
    }

    return (
        <Container style={{ margin: "0 10px"}}>
            {
                project && <Panel>
                    <FlexboxGrid justify="space-between" style={{ marginBottom: "20px"}}>
                        <FlexboxGrid.Item colspan={6}><SectionTitle>Manage Projects</SectionTitle></FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4}>{(project.status==='active')?<Tag color="green">Project is active</Tag>:<Tag color="red">Project is inactive</Tag>}</FlexboxGrid.Item>
                        <FlexboxGrid.Item colspan={4} style={{ textAlign: "right" }}><SelectPicker searchable={false} cleanable={false} defaultValue={id} size="lg" data={projects.map((elem, i)=>{ return {key:i+1, label: elem.name, value: elem.id} })} onChange={handleManage} style={{ width: 250 }}/></FlexboxGrid.Item>
                    </FlexboxGrid>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Project settings</h5>} className="mb-20" collapsible bordered defaultExpanded><Settings project={project}/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>SOLUTIONS</h5>} className="mb-20" collapsible bordered><Solutions/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>INSIGHTS</h5>} className="mb-20" collapsible bordered></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>USERS</h5>} className="mb-20" collapsible bordered><Users/></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Change log</h5>} className="mb-20" collapsible bordered></Panel>
                    <Panel header={<h5 style={{ color: "#6941c6" }}>Deactivate and delete</h5>} collapsible bordered><Deactivate/></Panel>
                </Panel>
            }
        </Container>
    )
}

export default ConfigProject;