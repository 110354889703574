import React, { useState } from "react";
import { Container, Checkbox, Toggle, Button } from "rsuite";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import EmailIcon from '@rsuite/icons/Email';
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import SectionTitle from "../ui_base/SectionTitle";
import { useNavigate } from "react-router-dom";
import styled from '@emotion/styled';
import { css } from "@emotion/react";
import theme from '../../styles/theme';
import {HeaderSmallest500Style,TextMedium500Style,TextSmall400Style} from '../styles/TextStyles';
import Panel from '../ui_base/Panel';
import CapiaGrid from "../ui_base/CapiaGrid";
import usersData from '../../data/usersData.json';

const ConfigTeam = () => {
    const navigate = useNavigate();
    const [data] = useState(usersData.users);

    const headers = [
        { title: 'Name', isSortable: true, isSearchable: true },
        { title: 'E-mail', isSortable: true, isSearchable: true },
        { title: 'Phone', isSortable: true, isSearchable: true },
        { title: 'Active', isSortable: false, isSearchable: false },
        { title: 'Solution access', isSortable: false, isSearchable: false },
        { title: 'Scenario access', isSortable: false, isSearchable: false },
        { title: 'Manager', isSortable: false, isSearchable: false },
        { title: 'Manage', isSortable: false, isSearchable: false }
    ];
    
    return (
        <>
            <SectionTitle>Manage team</SectionTitle>
            <Panel>
                <div style={{ margin: "20px 0", textAlign: "right" }}>
                    <Button appearance="ghost" color="violet" startIcon={<AddOutlineIcon />}>Add new user</Button>
                </div>
                <CapiaGrid headers={headers} maxDisplayedItems={8}>
                    {data.map((rowData, index) => [
                        rowData.name,
                        rowData.email,
                        rowData.phone,
                        <Toggle checked={rowData.active} color="orange" />,
                        <div style={{ whiteSpace: 'pre-wrap' }}>
                            {Object.keys(rowData.solution_access).map(e => (
                                <div key={e}>
                                    <Checkbox checked={rowData.solution_access[e]} color="orange">{e}</Checkbox>
                                </div>
                            ))}
                        </div>,
                        <Toggle checked={rowData.scenario_access} color="orange" />,
                        <Toggle checked={rowData.manager} color="orange" />,
                        <div>
                            <Button size="sm" appearance="subtle"><EmailIcon /></Button>
                            <Button size="sm" appearance="subtle"><TrashIcon /></Button>
                            <Button size="sm" appearance="subtle" onClick={() => navigate("/tracker")}><EditIcon /></Button>
                        </div>
                    ]).flat()}
                </CapiaGrid>
            </Panel>
        </>
    )
}

export default ConfigTeam;