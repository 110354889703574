import { useState } from "react";
import { Button, Container, FlexboxGrid, Form, Input, Panel, Schema, SelectPicker, Uploader } from "rsuite";
import { formats, intervals } from "../../utils/dropdowns";

const CreateDataset = () => {
    const [uploading, setUploading] = useState(false);
    const [formData, setFormData] = useState({
        file: '',
        name: '',
        source: '',
        interval: '',
        units: '',
        format: ''
    });

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        source: StringType().isRequired('Please enter source.'),
        interval: StringType().isRequired('Please select interval.'),
        units: StringType().isRequired('Please select units.'),
    });

    const handleInputChange = (key, value) => {
        setFormData(prevState => ({ ...prevState, [key]: value }));
    };

    const handleFileChange = async (files) => {
        if (!files || !files[0]) return;
        setUploading(true);
        // setPreview(URL.createObjectURL(files[0].blobFile));
        setFormData(prevState => ({ ...prevState, file: files[0].blobFile }));
        setUploading(false);
    }

    const handleSubmit = () => {
        console.log(formData);
    }

    return (
        <Container style={{ margin: "20px"}}>
            <Panel style={{ backgroundColor: "#ffffff"}} bordered>
                <Form model={model} onSubmit={handleSubmit}>
                    <div style={{ marginBottom: "20px" }}>
                        <FlexboxGrid justify="start">
                            <FlexboxGrid.Item colspan={8} style={{ marginRight: "10px"}}><Input width={400}/></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                                <Uploader 
                                    action=""
                                    autoUpload={false}
                                    fileListVisible={false}
                                    accept=".csv, .xls, .xlsx, text/csv, application/csv, text/comma-separated-values, application/csv, application/excel, application/vnd.msexcel, text/anytext, application/vnd. ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                                    onChange={handleFileChange}
                                    disabled={uploading}
                                    cleanable={false}
                                    multiple={false}>
                                    <Button appearance="primary" color="violet">Select file</Button>
                                </Uploader>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>       
                    </div>
                    <div style={{ marginBottom: "20px" }}>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item colspan={8}>
                                <Form.Group controlId="name">
                                    <Form.ControlLabel>Dataset name</Form.ControlLabel>
                                    <Form.Control type="text" name="name" value={formData.name} onChange={value=>handleInputChange('name', value)}></Form.Control>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                                <Form.Group controlId="source">
                                    <Form.ControlLabel>Source</Form.ControlLabel>
                                    <Form.Control type="text" name="source" value={formData.source} onChange={value=>handleInputChange('source', value)} style={{ width: "150px" }}></Form.Control>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                                <Form.Group controlId="interval">
                                    <Form.ControlLabel>Interval</Form.ControlLabel>
                                    <SelectPicker searchable={false} cleanable={false} data={intervals} value={formData.interval} onChange={value=>handleInputChange('interval', value)} style={{ width: "150px" }}></SelectPicker>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                                <Form.Group controlId="units">
                                    <Form.ControlLabel>Units</Form.ControlLabel>
                                    <SelectPicker searchable={false} cleanable={false} data={[]} value={formData.units} onChange={value=>handleInputChange('units', value)} style={{ width: "150px" }}></SelectPicker>
                                </Form.Group>
                            </FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={4}>
                                <Form.Group controlId="format">
                                    <Form.ControlLabel>Format</Form.ControlLabel>
                                    <SelectPicker searchable={false} cleanable={false} data={formats} value={formData.format} onChange={value=>handleInputChange('format', value)} style={{ width: "150px" }}></SelectPicker>
                                </Form.Group>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                </Form>
                
                <div style={{ textAlign: "right", margin: "10px" }}>
                    <Button appearance="primary" color="violet">SAVE DATASET</Button>
                </div>
            </Panel>
        </Container>
    )
}

export default CreateDataset;