import React, { useEffect, useState } from "react";
import { Button, Radio, Table, Toggle } from "rsuite";
import TrashIcon from '@rsuite/icons/Trash';
import EditIcon from '@rsuite/icons/Edit';
import { TagDefault } from "../../../utils/tag";
import { useLocation } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;
const Users = () => {
    const location = useLocation();
    const {id} = location.state || {};
    const [clientUsers, setClientUsers] = useState([]);
    
    useEffect(()=>{
        const getClientUsers = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/${id}/users`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const data = res.data;
            setClientUsers(data);
        }
        getClientUsers();
    }, [id]);
    
    return (
        <div>
            <Table data={clientUsers} height={400} autoHeight bordered rowHeight={50}>
                <Column flexGrow={2}>
                    <HeaderCell></HeaderCell>
                    <Cell>{rowData=>rowData.user.name}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Role</HeaderCell>
                    <Cell>{rowData=><TagDefault label={rowData.user.role}/>}</Cell>
                </Column>
                <Column flexGrow={2}>
                    <HeaderCell>E-mail</HeaderCell>
                    <Cell>{rowData=>rowData.user.email}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Phone</HeaderCell>
                    <Cell>{rowData=>rowData.user.phone}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Active</HeaderCell>
                    <Cell>{rowData=><Toggle color="green" checked={rowData.is_active}/>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Client contact</HeaderCell>
                    <Cell>{rowData=><Radio color="violet" checked={rowData.is_client_contact}/>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell></HeaderCell>
                    <Cell>{rowData=><><Button size="sm" appearance="subtle"><TrashIcon/></Button>
                    <Button size="sm" appearance="subtle"><EditIcon/></Button></>}</Cell>
                </Column>
            </Table>
        </div>
    );
}
export default Users;
