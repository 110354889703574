
import styled from '@emotion/styled';
import theme from '../../styles/theme';

const SectionTitle = styled.h1`
  font-family: 'Inter';
  line-height: 1.5rem;
  background-color: ${theme.colors.primaryLight};
  font-size: 1.3rem;
  color: #5c5c5c;
  border-radius: 1.2rem;
  padding: 0.9rem 1.2rem;
  margin-bottom: 1.0rem;
  margin-top: 1.0rem;
  display: inline-block;
  min-width: 30%;
`;

export default SectionTitle;