import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Notification from "../Notification";
import { Button, Container, Pagination, Panel, SelectPicker, Table, Tag } from "rsuite";
import FileDownloadIcon from '@rsuite/icons/FileDownload';
import EditIcon from "@rsuite/icons/Edit";
import ConversionIcon from '@rsuite/icons/Conversion';
import PageIcon from '@rsuite/icons/Page';
import TrashIcon from '@rsuite/icons/Trash';

const { Column, HeaderCell, Cell } = Table;

const ModelList = () => {
    const [datasetList, setDatasetList] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [searchParams, setSearchParams] = useSearchParams();
    const [artifacts, setArtifacts] = useState([{
        name: 'John Doe',
        solution: 'Tracker'
    }]);
    const [paginationItems, setPaginationItems] = useState([]);
    const [lastPage, setLastPage] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [notification, setNotification] = useState(null);
    const [showNotification, setShowNotification] = useState(false);
    const navigate = useNavigate();

    const handleChangePage = (page) => {
        setCurrentPage(page);
        setSearchParams({'page': page});
    }

    useEffect(()=>{
        const fetchDatasetList = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                setDatasetList(data.datasets);
            }
        }
        fetchDatasetList();
    },[]);

    useEffect(()=>{
        const fetchArtifacts = async (pageNo) => {
            setLoading(true);
            let url = `${process.env.REACT_APP_API_URL}/artifacts?page=${pageNo}&limit=${10}`;
            if(selectedDataset){
                url+=`&filter_by=dataset&dataset=${selectedDataset}`;
            }
            const response = await fetch(url, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const result = await response.json();
                setLoading(false);
                setArtifacts(result.artifacts);
                setLastPage(result.last_page);
                let pagination_items = [];
                for (let i = 1; i <= result.last_page; i++) {
                    pagination_items.push(
                        <Pagination.Item key={i} active={i === pageNo} onClick={()=>{setCurrentPage(i);}}>{i}</Pagination.Item>
                    );
                }
                setPaginationItems(pagination_items);
            }
        }
        if(searchParams.get('page')){
            setCurrentPage(+searchParams.get('page'));
            fetchArtifacts(+searchParams.get('page'));
        } else{
            fetchArtifacts(1);
        }
    },[refresh, currentPage, searchParams, selectedDataset]);

    const handleDelete = async(id) => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/artifacts/${id}`, { 
            method: 'DELETE',
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }
        });
        if(response.status === 200){
            setRefresh(new Date().getTime());
            setNotification("Model result deleted successfully");
            setShowNotification(true);
        }
    }

    const handleRerun = (title, dataset, fromDate, toDate, dependentDriver, independentDrivers, prognosis) => {
        const data = {
            title,
            dataset,
            fromDate,
            toDate,
            dependentDriver,
            independentDrivers,
            prognosis,
        }
        navigate(`/models/${data.title}`, {state: data});
    }

    const handleModifyAndRun = (title, dataset, fromDate, toDate, dependentDriver, independentDrivers, prognosis) => {
        const data = {
            title,
            dataset,
            fromDate,
            toDate,
            dependentDriver,
            independentDrivers,
            prognosis,
        }
        navigate(`/dashboard`, {state: data});
    }

    return (
        <Container style={{ margin: "20px" }}>
            {
                showNotification && <Notification variant='success' message={notification} onClose={() => setShowNotification(false)} duration={3000} />
            }
            <Panel header="My models" bordered>
                {
                    datasetList.length > 0 && <div className="text-end"><SelectPicker searchable={false} defaultValue={0} size="sm" data={datasetList.map((elem, i)=>{return{key:i+1, label: elem.name, value: elem.id}})} onChange={(value)=>setSelectedDataset(value)} style={{ minWidth: 224}} value={selectedDataset}/></div>
                }
                <Table data={artifacts} loading={!loading}>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Name</HeaderCell>
                        <Cell dataKey="name"></Cell>
                    </Column>
                    <Column flexGrow={1}>
                        <HeaderCell>Note</HeaderCell>
                        <Cell>{rowData=><FileDownloadIcon/>}</Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Solution</HeaderCell>
                        <Cell>{rowData=><Tag color="violet">{rowData.solution}</Tag>}</Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Last run date</HeaderCell>
                        <Cell>{rowData=>new Date().toLocaleDateString()}</Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Last fit score</HeaderCell>
                        <Cell>{rowData=><Tag>{rowData.fit_score}</Tag>}</Cell>
                    </Column>
                    <Column sortable flexGrow={1}>
                        <HeaderCell>Created by</HeaderCell>
                        <Cell>{rowData=>rowData.created_by}</Cell>
                    </Column>
                    <Column flexGrow={1}>
                        <HeaderCell>View/Run/Report/Delete</HeaderCell>
                        <Cell>{rowData=><>
                        <Button appearance="subtle" title="Modify and run" onClick={()=>handleModifyAndRun(rowData.title, rowData.dataset, rowData.from_date, rowData.to_date, rowData.dependent_driver, rowData.independent_drivers.map(e=>e.driver), rowData.prognosis)}><EditIcon/></Button>
                        <Button appearance="subtle" title="Re-run" onClick={()=>handleRerun(rowData.title, rowData.dataset, rowData.from_date, rowData.to_date, rowData.dependent_driver, rowData.independent_drivers.map(e=>e.driver), rowData.prognosis)}><ConversionIcon/></Button>
                        <Button appearance="subtle"><PageIcon/></Button>
                        <Button appearance="subtle" title="Delete" onClick={()=>handleDelete(rowData.id)}><TrashIcon/></Button>
                        </>}</Cell>
                    </Column>
                </Table>
                {
                    paginationItems && paginationItems.length > 1 && <Pagination>
                        <Pagination.First onClick={()=>handleChangePage(1)}/>
                        <Pagination.Prev onClick={()=>{if(currentPage > 1){handleChangePage(currentPage-1)}}}/>
                            {paginationItems}
                        <Pagination.Next onClick={()=>{if(currentPage < lastPage){handleChangePage(currentPage+1)} }}/>
                        <Pagination.Last onClick={()=>handleChangePage(lastPage)}/>
                    </Pagination>
                }
            </Panel>
        </Container>
    );
}
export default ModelList;