import React, { useEffect, useState } from "react";
import { Button, Container, Panel, Modal, Form, Message } from "rsuite";
import EditIcon from '@rsuite/icons/Edit';
import TrashIcon from '@rsuite/icons/Trash';
import useStandardToaster from '../../ui_base/StandardToaster';

const ManageFAQ = () => {
    const [faqData, setFaqData] = useState([]);
    const [editModalOpen, setEditModalOpen] = useState(false);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [newFaq, setNewFaq] = useState({ id: 0, question: '', answer: '' });
    const [editedFaq, setEditedFaq] = useState({ id: 0, question: '', answer: '' });
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getLearning = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const learning = res.data;
            setFaqData(learning.faq);
        }
        getLearning();
    }, [refresh]);

    const handleEditClick = (id) => {
        setEditedFaq(faqData.find(e=>e.id===id));
        setEditModalOpen(true);
    };

    const handleAddClick = () => {
        setNewFaq({ id: faqData.length+1, question: '', answer: '' });
        setAddModalOpen(true);
    };

    const handleChange = (value, field, isEdit = false) => {
        if (isEdit) {
            setEditedFaq((prev) => ({ ...prev, [field]: value }));
        } else {
            setNewFaq((prev) => ({ ...prev, [field]: value }));
        }
    };

    const handleSaveEdit = async(id) => {
        const objIndex = faqData.findIndex(obj => obj.id === id);
        faqData[objIndex] = editedFaq
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ faq: faqData }),
        });
        if (response.ok) {
            pushToast(<Message type="success">FAQ updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to edit faq.</Message>, { placement: 'topCenter', duration: 5000 });
        }
        setRefresh(new Date().getTime());
        setEditModalOpen(false);
    };

    const handleSaveNew = async() => {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ faq: [...faqData, newFaq] }),
        });
        if (response.ok) {
            pushToast(<Message type="success">FAQ added successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to add faq.</Message>, { placement: 'topCenter', duration: 5000 });
        }
        setRefresh(new Date().getTime());
        setAddModalOpen(false);
    };

    const handleDelete = async(id) => {
        const updatedData = faqData.filter(e=>e.id !== id);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings/`, {
            method: "PATCH",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({ faq: updatedData }),
        });
        if (response.ok) {
            pushToast(<Message type="success">FAQ deleted successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to delete faq.</Message>, { placement: 'topCenter', duration: 5000 });
        }
        setRefresh(new Date().getTime());
    };

    return (
        <Container style={{ margin: "20px 0" }}>
            <Panel bordered>
                <Button onClick={handleAddClick} appearance="primary" color="violet" style={{ marginBottom: "20px" }} block>
                    Add FAQ
                </Button>
                {faqData.map((elem, i) => (
                    <Panel
                        key={i+1}
                        header={
                            <span>
                                {elem.question}
                                <div style={{ float: "right" }}>
                                    <Button
                                        size="sm"
                                        appearance="ghost"
                                        color="violet"
                                        onClick={() => handleEditClick(elem.id)}
                                        style={{ marginRight: "5px" }}
                                    >
                                        <EditIcon />
                                    </Button>
                                    <Button size="sm" appearance="ghost" color="red" onClick={()=>handleDelete(elem.id)}>
                                        <TrashIcon />
                                    </Button>
                                </div>
                            </span>
                        }
                        bordered
                        style={{ marginBottom: "10px" }}
                    >
                        {elem.answer}
                    </Panel>
                ))}
            </Panel>

            <Modal backdrop="static" open={editModalOpen} onClose={() => setEditModalOpen(false)}>
                <Modal.Header>
                    <Modal.Title>Edit FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>Question</Form.ControlLabel>
                            <Form.Control
                                name="question"
                                value={editedFaq.question}
                                onChange={(value) => handleChange(value, 'question', true)}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Answer</Form.ControlLabel>
                            <Form.Control
                                name="answer"
                                value={editedFaq.answer}
                                onChange={(value) => handleChange(value, 'answer', true)}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={()=>handleSaveEdit(editedFaq.id)} appearance="primary">
                        Save
                    </Button>
                    <Button onClick={() => setEditModalOpen(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal backdrop="static" open={addModalOpen} onClose={() => setAddModalOpen(false)}>
                <Modal.Header>
                    <Modal.Title>Add FAQ</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form fluid>
                        <Form.Group>
                            <Form.ControlLabel>Question</Form.ControlLabel>
                            <Form.Control
                                name="question"
                                value={newFaq.question}
                                onChange={(value) => handleChange(value, 'question')}
                            />
                        </Form.Group>
                        <Form.Group>
                            <Form.ControlLabel>Answer</Form.ControlLabel>
                            <Form.Control
                                name="answer"
                                value={newFaq.answer}
                                onChange={(value) => handleChange(value, 'answer')}
                            />
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleSaveNew} appearance="primary">
                        Add
                    </Button>
                    <Button onClick={() => setAddModalOpen(false)} appearance="subtle">
                        Cancel
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
};

export default ManageFAQ;
