import { Tag } from "rsuite"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from "@fortawesome/free-solid-svg-icons";

export const TagSuccess = ({label, color="#12b76a", bg="#ecfdf3"}) => {
    return (<Tag style={{ color: color, backgroundColor: bg, textTransform: "capitalize", fontWeight: 500 }}><FontAwesomeIcon icon={faCircle} size="xs"/> {label}</Tag>)
}

export const TagWarning = ({label, color="#f79009", bg="#fffaeb"}) => {
    return (<Tag style={{ color: color, backgroundColor: bg, textTransform: "capitalize", fontWeight: 500 }}><FontAwesomeIcon icon={faCircle} size="xs"/> {label}</Tag>)
}

export const TagDanger = ({label, color="#f44336", bg="#ffebeb"}) => {
    return (<Tag style={{ color: color, backgroundColor: bg, textTransform: "capitalize", fontWeight: 500 }}><FontAwesomeIcon icon={faCircle} size="xs"/> {label}</Tag>)
}

export const TagDefault = ({label, color="#6941C6", bg="#E9D7FE", textTransform="capitalize"}) => {
    return (<Tag style={{ color: color, backgroundColor: bg, textTransform: textTransform, fontWeight: 500, borderRadius: "25px" }}>{label}</Tag>)
}