import React from 'react';
import { Text, Stack } from 'rsuite';
import ReportSubSection from './report_bases/ReportSubSection';
import InfoBoxLong from './report_bases/InfoBoxLong';
import InfoBox from './report_bases/InfoBox';

/**
 * Executive Summary component for the report
 * Displays high-level overview of the model results
 */
const ExecutiveSummary = () => {
    return (
        <div style={{ display: 'flex', flexDirection: 'column', gap: '30px' }}>
            <ReportSubSection 
                title="Model summary" 
                overviewText="Key measures about the variables and how well the model performs"
            >
                
                <Stack wrap spacing={6}>
                    <InfoBoxLong infoLabel="Outcome variable:" infoValue="Salg_total" />
                    <InfoBoxLong infoLabel="Drivers:" infoValue="92%" />
                    <InfoBoxLong infoLabel="Timeframe:" infoValue="Since beginning (June 2014)" />
                    <InfoBoxLong infoLabel="Prognosis:" infoValue="3 months ahead" />
                </Stack >
                
            </ReportSubSection>
        </div>
    );
};

export default ExecutiveSummary;