import { Tag } from "rsuite"
import caution from '../assets/icons/caution.png';
import warning from '../assets/icons/warning.png';
import checkbox from '../assets/icons/checkbox.png';

export const IconSuccess = () => {
    return (<img src={checkbox} width={20} height={20}/>)
}

export const IconWarning = () => {
    return (<span style={{ backgroundColor: "#ffd96650", padding: "8px 12px", borderRadius: "50px", margin: "auto" }}><img src={warning} width={15}/></span>)
}

export const IconDanger = () => {
    return (<span style={{ backgroundColor: "#ff000050", padding: "8px 12px", borderRadius: "50px", margin: "auto" }}><img src={caution} width={15}/></span>)
}