import { useNavigate } from "react-router-dom";
import { Button, Container, Panel, Stack, Text } from "rsuite";

const Learning = () => {
    const navigate = useNavigate();
    return (
        <Container style={{ margin: "20px"}}>
            <Panel>
                <Panel style={{ margin: "10px" }} bordered>
                    <Stack justifyContent="space-between">
                        <span><Text weight="bold" size="xxl" transform="uppercase">Quick-Start Intro</Text></span>
                        <Button appearance="primary" color="violet" onClick={()=>navigate("/learning/intro")}>Modify</Button>
                    </Stack>
                </Panel>
                <Panel style={{ margin: "10px" }} bordered>
                    <Stack justifyContent="space-between">
                        <span><Text weight="bold" size="xxl" transform="uppercase">Academy</Text></span>
                        <Button appearance="primary" color="violet" onClick={()=>navigate("/learning/academy")}>Modify</Button>
                    </Stack>
                </Panel>
                <Panel style={{ margin: "10px" }} bordered>
                    <Stack justifyContent="space-between">
                        <span><Text weight="bold" size="xxl" transform="uppercase">FAQ</Text></span>
                        <Button appearance="primary" color="violet" onClick={()=>navigate("/learning/faq")}>Modify</Button>
                    </Stack>
                </Panel>
            </Panel>
        </Container>
    );
}
export default Learning;