
import { css } from '@emotion/react';
import theme from '../../styles/theme';

/**
 * Base text css using Inter font with medium (500) weight
 * Inherits color from theme's standardText
 */
const TextMedium500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  color: ${theme.colors.standardText};
`;

const TextSmall400Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.8rem;
  color: ${theme.colors.fadedText};
`;

const TextSmall400FadedStyle = css`
  font-family: 'Inter', sans-serif;
  font-weight: 400;
  font-size: 0.7rem;
  color: ${theme.colors.veryFadedText};
`;

 const HeaderSmallest500Style = css`
  font-family: 'Inter', sans-serif;
  font-weight: 500;
  font-size: 0.625rem;
  color: ${theme.colors.veryFadedText};
`;
export  {TextMedium500Style,TextSmall400FadedStyle,TextSmall400Style, HeaderSmallest500Style};