import React, { useEffect, useState } from "react";
import { DateRangePicker, SelectPicker, Container, Panel, Button, Radio, Text, HStack, VStack, Tag, Input, FlexboxGrid, InputGroup, Table, Checkbox, IconButton, Divider } from "rsuite";
import format from 'date-fns/format';
import {quickSelectionDateOptions} from '../../../utils/dateRanges';
import { useLocation, useNavigate } from "react-router-dom";
import Notification from "../../Notification";
import { mockDrivers } from "../../../utils/mock";
import SearchIcon from '@rsuite/icons/Search';
import VisibleIcon from '@rsuite/icons/Visible';
import CheckOutlineIcon from '@rsuite/icons/CheckOutline';
import RemindFillIcon from '@rsuite/icons/RemindFill';
import CheckRoundIcon from '@rsuite/icons/CheckRound';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';

const data = mockDrivers(5);
const { Column, HeaderCell, Cell } = Table;
const rowKey = 'id';

const ROMI = ()=>{
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();
    const [datasetList, setDatasetList] = useState([]);
    const [selectedDataset, setSelectedDataset] = useState(null);
    const [driverList, setDriverList] = useState([]);
    const [dependentDriver, setDependentDriver] = useState(null);
    const [independentDrivers, setIndependentDrivers] = useState([]);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [prognosis, setPrognosis] = useState(0);
    const [error, setError] = useState(null);
    const [showNotification, setShowNotification] = useState(false);

    useEffect(()=>{
        const fetchDatasetList = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            if(response.status === 200){
                const data = await response.json();
                setDatasetList(data.datasets);
            }
        }
        fetchDatasetList();
    },[]);

    useEffect(()=>{
        const { dataset: dataSource, fromDate, toDate, dependentDriver, independentDrivers, prognosis } = location.state || {};
        if(datasetList.length > 0 && dataSource){
            const dataset = datasetList.find(e=>e.id===dataSource.id);
            const fillData = async ()=>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataSource.id}/info`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response.status === 200){
                    const data = await response.json();
                    const date_range = data.kpi;
                    Object.assign(dataset, {...date_range});
                }
                setSelectedDataset(dataset);
                setFromDate(fromDate);
                setToDate(toDate);
                setPrognosis(prognosis);
                const response2 = await fetch(`${process.env.REACT_APP_API_URL}/datasets/${dataset.id}/drivers?limit=100`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                if(response2.status === 200){
                    const data2 = await response2.json();
                    const drivers = data2.drivers;
                    setDriverList(drivers.map(e=>e.driver));
                    setDependentDriver(dependentDriver);
                    setIndependentDrivers(independentDrivers);
                }
            }
            fillData();
        }
    },[datasetList, location]);

    const handleDateRange = (value) =>{
        if(value){
            const [from, to] = value;
            if(from && to){
                const yearsDifference = to.getFullYear() - from.getFullYear();
                if(yearsDifference < 2){
                    setError('Select at least two years.')
                    setShowNotification(true);
                } else {
                    setFromDate(new Date(from).toISOString().split('T')[0]);
                    setToDate(new Date(to).toISOString().split('T')[0]);
                }
            }
        } else {
            setFromDate("");
            setToDate("");
        }
    }
    
    const handleRunModel = () => {
        if(!fromDate || !toDate){
            setError('Timeframe not set');
            setShowNotification(true);
        }
        else if(independentDrivers.length < 1){
            setError('Select at least one driver');
            setShowNotification(true);
        } else {
            const data = {
                title: `${fromDate}_${toDate}_${dependentDriver.name}_${new Date().getTime()}`,
                dataset: selectedDataset,
                fromDate,
                toDate,
                dependentDriver,
                independentDrivers,
                prognosis,
            }
            navigate(`/models/${data.title}`, {state: data});
        }
    }

    const renderRowExpanded = rowData => {
        return (
        <div>
            <div style={{ backgroundColor: "#fcf9fd", padding: "10px", borderRadius: "5px" }}>
                <FlexboxGrid justify="space-between">
                    <FlexboxGrid.Item>
                        <Text align="center">Total observations</Text>
                        <Text align="center">51</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Included observations</Text>
                        <Text align="center">38</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Missing</Text>
                        <Text align="center">None</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Units</Text>
                        <Text align="center">Percent</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Seasonality</Text>
                        <Text align="center">Detected</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Structural breaks</Text>
                        <Text align="center">None</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Std deviation</Text>
                        <Text align="center">3</Text>
                    </FlexboxGrid.Item>
                    <Divider vertical/>
                    <FlexboxGrid.Item>
                        <Text align="center">Mean</Text>
                        <Text align="center">51</Text>
                    </FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
            <div style={{ margin: "10px" }}>
                <FlexboxGrid justify="space-around">
                    <FlexboxGrid.Item>1</FlexboxGrid.Item>
                    <FlexboxGrid.Item>2</FlexboxGrid.Item>
                    <FlexboxGrid.Item>3</FlexboxGrid.Item>
                </FlexboxGrid>
            </div>
        </div>);
    };

    const handleExpanded = (rowData, dataKey) => {
        let open = false;
        const nextExpandedRowKeys = [];
    
        expandedRowKeys.forEach(key => {
            if (key === rowData[rowKey]) {
                open = true;
            } else {
                nextExpandedRowKeys.push(key);
            }
        });
    
        if (!open) {
            nextExpandedRowKeys.push(rowData[rowKey]);
        }
    
        setExpandedRowKeys(nextExpandedRowKeys);
    };

    return (
        <Container style={{ margin: "20px" }}>
            {
                showNotification && <Notification variant='danger' message={error} onClose={() => setShowNotification(false)} duration={3000} />
            }
            <Panel header="Set Timeframe" style={{ marginBottom: "10px" }} bordered>
                <VStack spacing={20} style={{ margin: "20px"}}>
                    <HStack spacing={20}>
                        <Text>Interval: </Text>
                        {
                            selectedDataset && 
                            <DateRangePicker 
                                size="sm"
                                ranges={quickSelectionDateOptions(selectedDataset.date_start, selectedDataset.date_end)}
                                format="yyyy-MM-dd"
                                renderValue={([start, end]) => {
                                    return format(start, 'EEE, d MMM Y') + ' - ' + format(end, 'EEE, d MMM Y');
                                }}
                                shouldDisableDate={(date)=>{
                                    return (date < new Date(`${selectedDataset.date_start} 00:00:00`) || date > new Date(`${selectedDataset.date_end} 23:59:00`))
                                }}
                                value={(fromDate && toDate)?[new Date(fromDate), new Date(toDate)]:''}
                                onChange={handleDateRange}
                                cleanable={false}
                            />
                        }
                    </HStack>
                    <HStack>
                        <Text>Optimization:</Text>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="sm" data={[{key:0, value: 0, label: 'One month'}]} style={{ minWidth: 224}}/>
                    </HStack>
                </VStack>
            </Panel>
            <Panel header="Set parameters" style={{ marginBottom: "10px" }} bordered>
                <VStack spacing={30}>
                    <HStack spacing={20} wrap={true}>
                        <Text>Set budget:</Text>
                        <Radio checked/>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="xxl" data={[{key:0, value: 0, label: 'Fixed'}]}/>
                        <Radio/>
                        <Input style={{width:"100px"}}/>
                        <Divider vertical/>
                        <Radio checked/>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="xxl" data={[{key:0, value: 0, label: '+/-30%'}]}/>
                    </HStack>
                    <HStack spacing={20}>
                        <Text>Choose function:</Text>
                        <Radio checked/>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="xxl" data={[{key:0, value: 0, label: 'Basic ad stock'}]}/>
                    </HStack>
                    <HStack spacing={20} wrap={true}>
                        <Text>Lag weight:</Text>
                        <Radio checked/>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="xxl" data={[{key:0, value: 0, label: 'Normal'}]}/>
                        <Radio/>
                        <Input style={{width:"100px"}}/>
                        <Divider vertical/>
                        <Text>Decay rate:</Text>
                        <Radio checked/>
                        <SelectPicker searchable={false} cleanable={false} defaultValue={0} size="xxl" data={[{key:0, value: 0, label: 'Normal'}]}/>
                        <Radio/>
                        <Input style={{width:"200px"}}/>
                    </HStack>
                </VStack>
            </Panel>
            <Panel header="Select channels" bordered>
                <HStack style={{ marginBottom: "20px" }}>
                    <Text>Dependent variable</Text>
                    <SelectPicker data={driverList.map((driver, i)=>{ return {key: i, label: driver.display_name, value: driver.id}})} size="sm" searchable={false} style={{minWidth: 224}} onChange={(value)=>setDependentDriver(driverList.find(e=>e.id===value))} value={dependentDriver?.id} cleanable={false}/>
                </HStack>
                <Panel bordered>
                    <div className="show-grid">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item colspan={3}><Text weight="bold">Available drivers: 21</Text></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={3}><Text color="violet" style={{ background: "#f3eaf8", padding: "0 10px", borderRadius: "5px" }}>8 drivers selected</Text></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <InputGroup>
                                    <Input placeholder="Find channel" />
                                    <InputGroup.Button>
                                        <SearchIcon />
                                    </InputGroup.Button>
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                    <Table 
                        data={data} 
                        height={400} 
                        rowHeight={50} 
                        autoHeight 
                        rowKey={rowKey}
                        expandedRowKeys={expandedRowKeys}
                        onRowClick={data => {
                            console.log(data);
                        }}
                        renderRowExpanded={renderRowExpanded}
                    >
                        <Column flexGrow={4}>
                            <HeaderCell><Checkbox>Select all/none</Checkbox></HeaderCell>
                            <Cell>{rowData=>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item colspan={8}><Checkbox>{rowData.name}</Checkbox></FlexboxGrid.Item>
                                    {
                                        (rowData.id===1)&&<FlexboxGrid.Item colspan={4}><Tag color="violet" style={{ textAlign: "right" }}>Dependent</Tag></FlexboxGrid.Item>
                                    }
                                </FlexboxGrid>}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Source</HeaderCell>
                            <Cell dataKey="source"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Last Update</HeaderCell>
                            <Cell dataKey="updated_at"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Observations</HeaderCell>
                            <Cell dataKey="observations"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Units</HeaderCell>
                            <Cell dataKey="units"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Quality check</HeaderCell>
                            <Cell>
                                {
                                    rowData=>{
                                        if(rowData.quality_check===1){
                                            return <IconButton circle icon={<CheckRoundIcon color="green"/>} appearance="subtle"/>
                                        }
                                        if(rowData.quality_check===2){
                                            return <IconButton circle icon={<RemindFillIcon color="orange"/>} appearance="subtle"/>
                                        }
                                        if(rowData.quality_check===3){
                                            return <IconButton circle icon={<RemindFillIcon color="red"/>} appearance="subtle"/>
                                        }
                                    }
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>View</HeaderCell>
                            <Cell>{rowData=>
                                <IconButton
                                appearance="subtle"
                                onClick={() => {
                                    handleExpanded(rowData);
                                }}
                                icon={
                                  expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                                    <CollaspedOutlineIcon />
                                  ) : (
                                    <VisibleIcon />
                                  )
                                }
                              />}</Cell>
                        </Column>
                    </Table>
                </Panel>
            </Panel>
            <Panel header="Select contextual drivers" bordered>
                <Panel bordered>
                    <div className="show-grid">
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item colspan={3}><Text weight="bold">Available drivers: 21</Text></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={3}><Text color="violet" style={{ background: "#f3eaf8", padding: "0 10px", borderRadius: "5px" }}>8 drivers selected</Text></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}>
                                <InputGroup>
                                    <Input placeholder="Find channel" />
                                    <InputGroup.Button>
                                        <SearchIcon />
                                    </InputGroup.Button>
                                </InputGroup>
                            </FlexboxGrid.Item>
                        </FlexboxGrid>
                    </div>
                    <Table 
                        data={data} 
                        height={400} 
                        rowHeight={50} 
                        autoHeight 
                        rowKey={rowKey}
                        expandedRowKeys={expandedRowKeys}
                        onRowClick={data => {
                            console.log(data);
                        }}
                        renderRowExpanded={renderRowExpanded}
                    >
                        <Column flexGrow={4}>
                            <HeaderCell><Checkbox>Select all/none</Checkbox></HeaderCell>
                            <Cell>{rowData=>
                                <FlexboxGrid justify="space-between">
                                    <FlexboxGrid.Item colspan={8}><Checkbox>{rowData.name}</Checkbox></FlexboxGrid.Item>
                                    {
                                        (rowData.id===1)&&<FlexboxGrid.Item colspan={4}><Tag color="violet" style={{ textAlign: "right" }}>Dependent</Tag></FlexboxGrid.Item>
                                    }
                                </FlexboxGrid>}
                            </Cell>
                        </Column>
                        <Column flexGrow={1} sortable>
                            <HeaderCell>Source</HeaderCell>
                            <Cell dataKey="source"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Last Update</HeaderCell>
                            <Cell dataKey="updated_at"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Observations</HeaderCell>
                            <Cell dataKey="observations"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Units</HeaderCell>
                            <Cell dataKey="units"></Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>Quality check</HeaderCell>
                            <Cell>
                                {
                                    rowData=>{
                                        if(rowData.quality_check===1){
                                            return <IconButton circle icon={<CheckRoundIcon color="green"/>} appearance="subtle"/>
                                        }
                                        if(rowData.quality_check===2){
                                            return <IconButton circle icon={<RemindFillIcon color="orange"/>} appearance="subtle"/>
                                        }
                                        if(rowData.quality_check===3){
                                            return <IconButton circle icon={<RemindFillIcon color="red"/>} appearance="subtle"/>
                                        }
                                    }
                                }
                            </Cell>
                        </Column>
                        <Column flexGrow={1}>
                            <HeaderCell>View</HeaderCell>
                            <Cell>{rowData=>
                                <IconButton
                                appearance="subtle"
                                onClick={() => {
                                    handleExpanded(rowData);
                                }}
                                icon={
                                  expandedRowKeys.some(key => key === rowData[rowKey]) ? (
                                    <CollaspedOutlineIcon />
                                  ) : (
                                    <VisibleIcon />
                                  )
                                }
                              />}</Cell>
                        </Column>
                    </Table>
                </Panel>
            </Panel>
            <Panel>
                <div style={{ textAlign: "right" }}>
                    <Button appearance="primary" color="green" size="xxl" onClick={()=>handleRunModel()} disabled={(selectedDataset && dependentDriver)?'':'disabled'} startIcon={<CheckOutlineIcon/>}>Run</Button> 
                </div>
            </Panel>
            
        </Container>
    );
}
export default ROMI;