import { css } from '@emotion/react';

const CircleButton = css`
    width: 2.8rem ;
    height: 2.8rem ;
    min-width: 2.8rem ;
    min-height: 2.8rem ;
    flex: 0 0 2.8rem ;
    border-radius: 50% ;
    background-color: transparent;
    padding: 0 ;
    border: none;
    cursor: pointer;
    display: flex ;
    align-items: center ;
    justify-content: center ;

    &:hover {
        background-color: #f5f5f5 ;
    }

    &:active {
        background-color: #d1d1d1;
    }

    // Set consistent size for all icons inside
    svg, img {
            width: 1.4rem ;
            height: 1.4rem ;
        }
`;

export default CircleButton;