export const formats = [
    {
        key: 1,
        label: 'Standard',
        value: 'standard'
    },
    {
        key: 1,
        label: 'Semi-standard',
        value: 'semi-standard'
    },
    {
        key: 1,
        label: 'Non-standard',
        value: 'non-standard'
    },
    {
        key: 1,
        label: 'Unformatted',
        value: 'unformatted'
    }
];

export const intervals = [
    {
        key: 1,
        label: 'Daily',
        value: 'daily'
    },
    {
        key: 2,
        label: 'Weekly',
        value: 'weekly'
    },
    {
        key: 3,
        label: 'Monthly',
        value: 'monthly'
    },
    {
        key: 4,
        label: 'Quarterly',
        value: 'quarterly'
    },
    {
        key: 5,
        label: 'Annually',
        value: 'annually'
    }
];