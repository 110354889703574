import React from "react";
import { useAuth } from "../context/AuthContext";
import { useEffect, useRef, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faSave } from "@fortawesome/free-solid-svg-icons";
import { Tag, Uploader, Message, Input, Text } from 'rsuite';
import Footer from "./Footer";
import styled from '@emotion/styled';
import Panel from "./ui_base/Panel"; 
import CircleButtonStyle from './styles/CircleButtonStyle';
import theme from '../styles/theme';
import SectionTitle from './ui_base/SectionTitle';
import StandardButton from "./ui_base/StandardButton";
import { PanelIndentSection } from "./ui_base/PanelIndentSection";
import profileSymbol from '../assets/icons/profile.svg';
import useStandardToaster from './ui_base/StandardToaster'; // Updated import
import { TextMedium500Style } from './styles/TextStyles';
import {TagDefault} from "../utils/tag";


const Container = styled.div`
  margin: 60px;
`;
const TwoColumnsInPanel = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: top;
`;

const Grid = styled.div`
  display: grid;

  grid-template-columns: minmax(auto, max-content) 1fr minmax(auto, max-content);
  grid-template-rows: 2rem 2rem 2rem;;
  gap: 10px;
  padding: 10px;

  
  & > * { /* Targets all direct children */
    align-self: center;       /* Vertically center the content */
  }

    //Third column need to be right aligned
  & > :nth-child(3n) {
    justify-self: end;
  }

  //First column need margin
  & > :nth-child(3n-2) {
    margin-right: 2rem;
  }

  & span {
    ${TextMedium500Style}
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  label {
    display: block;
    margin-bottom: 0.5rem;
  }

  .input-group {
    margin-bottom: 1rem;
  }

  .d-block {
    color: ${theme.colors.error};
    margin-top: 0.5rem;
  }
`;

const Button = styled.button`
  ${CircleButtonStyle}
  // Track hover state using a CSS custom property
  --icon-color: ${theme.colors.standardText};
  
  &:hover {
    --icon-color: '#000'};
  }


`;

const FlexContainer = styled.div`
  display: flex;
    flex-direction: row;
    align-items: center;
`;

// Remove these styled components as they're no longer needed
const BaseFieldStyle = styled.div`
  padding: 5px 11px;
  min-height: 36px;
  display: flex;
  align-items: center;
`;

const TextDisplay = styled(BaseFieldStyle)`
  ${TextMedium500Style}
  line-height: 1.42857143;
  color: ${theme.colors.standardText};
`;

// StyledInput component for handling both edit and view modes of RSuite Input
// RSuite creates different DOM structures:
// - Edit mode: <input class="rs-input rs-input-sm">
// - View mode: <div role="text" class="rs-plaintext">
/* Container to maintain consistent width for both modes */
const FieldContainer = styled.div`
  width: 4rem;
  min-width: 200px; /* Prevents field from becoming too narrow */
  max-width: 300px; /* Prevents field from expanding too much */
  height: 32px; /* Match RSuite's small input height */
`;

/* Base styles for both input and plaintext */
const commonFieldStyles = `
  ${TextMedium500Style}
  width: 4rem !important;
  height: 32px !important;
  padding: 5px 11px !important;
  line-height: 20px !important;
  border: 1px solid ${theme.colors.grey300} !important;
  border-radius: 6px !important;
  background-color: #fff !important;
  box-sizing: border-box !important;
`;

/* StyledPlaintext with exact RSuite input dimensions */
const StyledPlaintext = styled.div`
  ${commonFieldStyles}
  display: flex !important;
  align-items: center !important;

  & .rs-plaintext {
    margin: 0 !important;
    padding: 0 !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

/* StyledInput with exact same dimensions */
const StyledInput = styled(Input)`
  ${commonFieldStyles}
`;

/* Wrapper to ensure consistent sizing for both input and plaintext modes */
const InputWrapper = styled.div`
  width: 300px;
  display: flex;
  align-items: center;

  /* Style for both input and plaintext */
  .rs-input,
  .rs-plaintext {
    width: 100% !important;
    height: 32px !important;
    padding: 5px 11px !important;
    font-size: 14px !important;
    line-height: 20px !important;
    border-radius: 6px !important;
    background-color: #fff !important;
    box-sizing: border-box !important;
  }

  /* Additional plaintext specific styles */
  .rs-plaintext {
    display: flex !important;
    align-items: center !important;
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
  }
`;

/* EditableField component with uniform styling */
const EditableField = ({ isEditing, value, onChange, type = "text" }) => (
    <InputWrapper>
        <Input 
            type={type}
            size="sm"
            value={value}
            onChange={onChange}
            plaintext={!isEditing}
        />
    </InputWrapper>
);

const Profile = () => {
    const [userData, setUserData] = useState(null);

    const [refresh, setRefresh] = useState(new Date().getTime());
    const [isEditingName, setEditName] = useState(false);
    const [name, setName] = useState("");
    
    const [isEditingEmail, setEditEmail] = useState(false);
    const [email, setEmail] = useState("");

    const [isEditingPhone, setEditPhone] = useState(false);
    const [phone, setPhone] = useState("");

    const { pushToast } = useStandardToaster(); // Updated toaster usage
    const [uploading, setUploading] = useState(false);
    const [uploadKey, setUploadKey] = useState(0); // Add this new state

    const [showChangePassword, setShowChangePassword] = useState(false);
    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [message, setMessage] = useState('');
    const [validationErrors, setValidationErrors] = useState({});
    const [error, setError] = useState(false);

    // Add these new state variables to track original values
    const [originalName, setOriginalName] = useState("");
    const [originalEmail, setOriginalEmail] = useState("");
    const [originalPhone, setOriginalPhone] = useState("");

        //a styled container with an avatar image as background image
    const AvatarStylizedContainer = styled.div`
        width: 10rem ;
        height: 10rem ;
        min-width: 10rem ;
        min-height: 10rem ;
        flex: 0 0 2.8rem ;
        border-radius: 50% ;
        padding: 0 ;
        margin-right: 2rem;
        border: none;
        cursor: pointer;
        display: flex ;
        align-items: center ;
        justify-content: center ;
        border: 1rem solid white;
        background-image: url(${userData && userData.avatar ? `${process.env.REACT_APP_API_URL}/${userData.avatar}` : profileSymbol});
        //the size will change uppon if process.env.REACT_APP_API_URL}/${userData.avatar} exists or not. 100% if it exists, 40% if it doesn't
        background-size: ${userData && userData.avatar ? '100%' : '40%'};
        background-position: center;
        background-repeat: no-repeat;
        background-color: ${theme.colors.primaryVeryLight};
        stroke: white;
        stroke-width: 5px;
        box-shadow: 0 1.5rem 1rem -0.5rem rgba(0, 0, 0, 0.15);

    `;

    const ButtonContainer = styled.div`
        display: flex;
        justify-content: flex-start;
    `;

    const ButtonContainerInline = styled.div`
        display: inline-block;  // This will make the container only as wide as its content
    `;

    useEffect(() => {
        const getUserInfo = async () => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const res = await response.json();
            const data = res.data;
            setUserData(data);

            // Set both current and original values
            setName(data.name);
            setEmail(data.email);
            setPhone(data.phone);
            
            // Store original values
            setOriginalName(data.name);
            setOriginalEmail(data.email);
            setOriginalPhone(data.phone);
        }
        getUserInfo();
    }, [refresh]);

    const hasChanges = () => {
        return name !== originalName ||
               email !== originalEmail ||
               phone !== originalPhone;
    };

    const updateProfile = async () => {
        // Only update if there are actual changes
        if (!hasChanges()) {
            pushToast(<Message type="info">No changes to update</Message>, { placement: 'topCenter', duration: 5000 });
            return;
        }

        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            method: 'PATCH',
            body: JSON.stringify({
                name,
                email,
                phone
            })
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Profile updated successfully</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update profile</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleFileChange = async (files) => {
        if (!files || !files[0]) return;
        
        setUploading(true);
        const formData = new FormData();
        formData.append('avatar', files[0].blobFile);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/userinfo/`, {
                method: 'PATCH',
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                },
                body: formData,
            });

            if (response.status === 200) {
                // Force re-mount of Uploader by changing key
                setUploadKey(prev => prev + 1);
                setRefresh(new Date().getTime());
                pushToast(<Message type="success">Uploaded successfully</Message>, { placement: 'topCenter', duration: 5000 });
            } else {
                throw new Error('Upload failed');
            }
        } catch (error) {
            pushToast(<Message type="error">Upload failed</Message>, { placement: 'topCenter', duration: 5000 });
            console.error('Failed to upload avatar:', error);
        } finally {
            setUploading(false);
        }
    };

    const handleUpdatePassword = async (event) => {
        event.preventDefault();
        setMessage('');
        setError(false);

        const requestData = {
            old_password: oldPassword,
            new_password: newPassword,
        };

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/auth/change_password/`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(requestData),
            });

            if (response.ok) {
                setMessage('Password updated successfully!');
                setOldPassword('');
                setNewPassword('');
            } else {
                const errorData = await response.json();
                if (errorData.message.old_password || errorData.message.new_password) {
                    setValidationErrors(errorData.message);
                } else {
                    setMessage(errorData.message || 'Failed to update password');
                    setError(true);
                }
            }
        } catch (err) {
            setMessage('An error occurred. Please try again later.');
            setError(true);
        }
    }

    return (
        <>
            <SectionTitle >Profile settings</SectionTitle>
            <Panel>
                <PanelIndentSection>
                    {userData && (
                        
                        <TwoColumnsInPanel >
                            <Grid>
                                <span>Name:</span>
                                <EditableField 
                                    isEditing={isEditingName}
                                    value={name}
                                    onChange={(value) => setName(value)}
                                />
                                <Button onClick={() => {
                                    if (isEditingName) {
                                        updateProfile();
                                    }
                                    setEditName(!isEditingName);
                                }}>
                                    <FontAwesomeIcon 
                                        icon={!isEditingName ? faEdit : faSave} 
                                        color="var(--icon-color)"
                                    />
                                </Button>

                                <span>Email:</span>
                                <EditableField 
                                    isEditing={isEditingEmail}
                                    value={email}
                                    onChange={(value) => setEmail(value)}
                                    type="email"
                                />
                                <Button onClick={() => {
                                    if (isEditingEmail) {
                                        updateProfile();
                                    }
                                    setEditEmail(!isEditingEmail);
                                }}>
                                    <FontAwesomeIcon 
                                        icon={!isEditingEmail ? faEdit : faSave} 
                                        color="var(--icon-color)"
                                    />
                                </Button>

                                <span>Phone:</span>
                                <EditableField 
                                    isEditing={isEditingPhone}
                                    value={phone}
                                    onChange={(value) => setPhone(value)}
                                />
                                <Button onClick={() => {
                                    if (isEditingPhone) {
                                        updateProfile();
                                    }
                                    setEditPhone(!isEditingPhone);
                                }}>
                                    <FontAwesomeIcon 
                                        icon={!isEditingPhone ? faEdit : faSave} 
                                        color="var(--icon-color)"
                                    />
                                </Button>

                                <span>Role:</span>
                                <div><TagDefault label={userData?.role.toUpperCase()}/></div>
                            </Grid>
                            <div>
                                <FlexContainer>
                                    <AvatarStylizedContainer/>
   
                                    <Uploader
                                        key={uploadKey} // Add this key prop
                                        autoUpload={false}
                                        fileListVisible={false}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={uploading}
                                        cleanable={false}
                                        multiple={false}
                                    >
                                        <StandardButton disabled={uploading}>
                                            {'Upload avatar'}
                                        </StandardButton>
                                    </Uploader>
                                </FlexContainer>
                            </div>
                        </TwoColumnsInPanel>
                        
                    )}
                    <div className="mt-20">
                        <StandardButton onClick={() => setShowChangePassword(!showChangePassword)} >Update password</StandardButton>
                        {showChangePassword && (
                            <div className="panel-password">
                                {message && <Message type={error ? 'error' : 'success'}>{message}</Message>}
                                <Form onSubmit={handleUpdatePassword}>
                                    <div className="input-group">
                                        <label css={TextMedium500Style}>Old password</label>
                                        <Input 
                                            name="old_password" 
                                            size="sm" 
                                            type="password" 
                                            placeholder="Old password" 
                                            value={oldPassword} 
                                            onChange={(value) => setOldPassword(value)} 
                                            required 
                                        />
                                        {validationErrors.old_password && (
                                            <div className="d-block">
                                                {validationErrors.old_password[0]}
                                            </div>
                                        )}
                                    </div>
                                    <div className="input-group">
                                        <label css={TextMedium500Style}>New password</label>
                                        <Input 
                                            name="new_password" 
                                            size="sm" 
                                            type="password" 
                                            placeholder="New password" 
                                            value={newPassword} 
                                            onChange={(value) => setNewPassword(value)} 
                                            required 
                                        />
                                        {validationErrors.new_password && (
                                            <div className="d-block">
                                                {validationErrors.new_password[0]}
                                            </div>
                                        )}
                                    </div>
                                    <ButtonContainerInline>
                                        <StandardButton type="submit">Submit</StandardButton>
                                    </ButtonContainerInline>
                                </Form>
                            </div>
                        )}
                    </div>
                </PanelIndentSection>
            </Panel>
            <Footer />  
            
        </>
    );
}
export default Profile;

