import { Button, Container, FlexboxGrid, IconButton, Input, InputGroup, Panel, Stack, Table, Text } from "rsuite";
import React, { useEffect, useState } from "react";
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import MinusRoundIcon from '@rsuite/icons/MinusRound';
import SearchIcon from '@rsuite/icons/Search';
import VisibleIcon from '@rsuite/icons/Visible';
import EditIcon from '@rsuite/icons/Edit';
import { useNavigate } from "react-router-dom";
import {TagSuccess, TagDanger, TagWarning} from "../../utils/tag";
import CreateDataset from "./Create";
import SectionTitle from "../ui_base/SectionTitle";

const { Column, HeaderCell, Cell } = Table;

const DatasetList = () => {
    const navigate = useNavigate();
    const [sortColumn, setSortColumn] = useState();
    const [sortType, setSortType] = useState();
    const [loading, setLoading] = useState(false);
    const [refresh, setRefresh] = useState(new Date().getTime());
    const [showCreateDataset, setShowCreateDataset] = useState(false);

    const [total, setTotal] = useState(0);
    const [datasets, setDatasets] = useState([]);

    useEffect(()=>{
        const getDatasets = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/datasets/`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setTotal(res.total);
            setDatasets(res.data);
        }
        getDatasets();
    }, [refresh]);

    const getData = () => {
        if (sortColumn && sortType) {
            return datasets.sort((a, b) => {
                let x = a[sortColumn];
                let y = b[sortColumn];
                if (typeof x === 'string') {
                    x = x.charCodeAt();
                }
                if (typeof y === 'string') {
                    y = y.charCodeAt();
                }
                if (sortType === 'asc') {
                    return x - y;
                } else {
                    return y - x;
                }
            });
        }
        return datasets;
    };

    const handleSortColumn = (sortColumn, sortType) => {
        setLoading(true);
        setTimeout(() => {
            setLoading(false);
            setSortColumn(sortColumn);
            setSortType(sortType);
        }, 500);
    };

    const getRowHeight = (rowData) => {
        const baseHeight = 40; // Minimum row height
        const additionalHeightPerProject = 25; // Height per project item
        if (!rowData || !Array.isArray(rowData.projects)) {
            return baseHeight;
        }
        return baseHeight + rowData.projects.length * additionalHeightPerProject;
    };

    const handleView = (id) => {
    }

    const handleManage = (id) => {
        navigate('/datasets/'+id);
    }

    return (
        <Container style={{ margin: "10px 0"}}>
            <Panel>
                <SectionTitle>Manage drivers</SectionTitle>
            </Panel>
            <Panel style={{ backgroundColor: "#ffffff"}} bordered>
                <Panel className="mb-20" bordered style={{ backgroundColor: "#f9f5ff"}}>
                    <Stack justifyContent="space-between">
                        <span><Text weight="bold" size="xxl">Add new dataset</Text></span>
                        <IconButton icon={showCreateDataset?<MinusRoundIcon/>:<AddOutlineIcon/>} color="violet" appearance="ghost" onClick={()=>setShowCreateDataset(!showCreateDataset)} circle></IconButton>
                    </Stack>
                    {
                        showCreateDataset && <CreateDataset setRefresh={setRefresh} setShowCreateDataset={setShowCreateDataset}/>
                    }
                </Panel>
                <Panel bordered>
                    <Panel>
                        <FlexboxGrid justify="space-between">
                            <FlexboxGrid.Item colspan={6}><Text weight="bold">Total datasets: {total}</Text></FlexboxGrid.Item>
                            <FlexboxGrid.Item colspan={6}><InputGroup><InputGroup.Button><SearchIcon /></InputGroup.Button><Input placeholder="Find dataset"/></InputGroup></FlexboxGrid.Item>
                        </FlexboxGrid>
                    </Panel>
                    <Table
                        height={400} 
                        autoHeight
                        data={getData()}
                        sortColumn={sortColumn}
                        sortType={sortType}
                        onSortColumn={handleSortColumn}
                        loading={loading}
                        rowHeight={(rowData) => getRowHeight(rowData)} 
                        >
                        <Column fixed sortable flexGrow={2}>
                            <HeaderCell>Dataset name</HeaderCell>
                            <Cell dataKey="name"/>
                        </Column>

                        <Column fixed sortable flexGrow={1}>
                            <HeaderCell>Source</HeaderCell>
                            <Cell dataKey="source"/>
                        </Column> 

                        <Column flexGrow={1} align="center">
                            <HeaderCell>Interval</HeaderCell>
                            <Cell dataKey="interval"/>
                        </Column>

                        <Column sortable flexGrow={2}>
                            <HeaderCell>Last updated</HeaderCell>
                            <Cell>{rowData=>`${new Date(rowData.created_at).toLocaleDateString()}@${new Date(rowData.created_at).getHours()}:${new Date(rowData.created_at).getMinutes()}`}</Cell>
                        </Column>

                        <Column flexGrow={1} align="center">
                            <HeaderCell>Observations</HeaderCell>
                            <Cell dataKey="observations"/>
                        </Column>

                        <Column flexGrow={1} align="center">
                            <HeaderCell>Units</HeaderCell>
                            <Cell dataKey="units"/>
                        </Column>

                        <Column flexGrow={1} align="center">
                            <HeaderCell>Quality</HeaderCell>
                            <Cell>{rowData=>{
                                if(rowData.quality==="good"){
                                    return (<TagSuccess label={rowData.quality}/>)
                                }
                                else if(rowData.quality==="check"){
                                    return (<TagWarning label={rowData.quality}/>)
                                }
                                else if(rowData.quality==="poor"){
                                    return (<TagDanger label="Fix"/>)
                                }
                            }}</Cell>
                        </Column>

                        <Column flexGrow={1} align="center">
                            <HeaderCell>View/Edit</HeaderCell>
                            <Cell>{rowData =><><Button size="sm" onClick={() => handleView(rowData.id)} appearance="subtle"><VisibleIcon/></Button><Button size="sm" onClick={() => handleManage(rowData.id)} appearance="subtle"><EditIcon/></Button></>}</Cell>
                        </Column>
                    </Table>
                </Panel>
            </Panel>
        </Container>
    )
}

export default DatasetList;