import React, { useEffect } from "react";
import { Checkbox, Table, Toggle } from "rsuite";
import { useState } from "react";
import { useLocation } from "react-router-dom";

const { Column, HeaderCell, Cell } = Table;

const Users = () => {
    const [projectUsers, setProjectUsers] = useState([]);
    const location = useLocation();
    const {id} = location.state || {};

    useEffect(()=>{
        const getProjectUsers = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/users`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjectUsers(res.data);
        }
        getProjectUsers();
    },[id]);

    const handleToggleChange = (rowId) => {
        setProjectUsers((prevProjectUsers) =>
            prevProjectUsers.map((projectUser) =>
                projectUser.id === rowId
                    ? { ...projectUser, has_scenario_access: !projectUser.has_scenario_access }
                    : projectUser
            )
        );
    };

    return (
        <Table height={400} autoHeight rowHeight={50} data={projectUsers} bordered>
            <Column flexGrow={1}>
                <HeaderCell></HeaderCell>
                <Cell>{rowData=>rowData.user.name}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>E-mail</HeaderCell>
                <Cell>{rowData=>rowData.user.email}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Phone</HeaderCell>
                <Cell>{rowData=>rowData.user.phone}</Cell>
            </Column>
            <Column flexGrow={2}>
                <HeaderCell>Solution access</HeaderCell>
                <Cell>{rowData=> rowData.solutions.map((solution, i)=><Checkbox key={i+1} checked={solution.has_access} color="orange">{solution.name}</Checkbox>)}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Scenario access</HeaderCell>
                <Cell>{rowData=><Toggle checked={rowData.has_scenario_access} onChange={()=>handleToggleChange(rowData.id)} color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Manager</HeaderCell>
                <Cell>{rowData=><Checkbox checked={rowData.user.role==='manager'} color="orange"/>}</Cell>
            </Column>
        </Table>
    );
}
export default Users;
