
import { css } from '@emotion/react';
import theme from '../../styles/theme';
import { Panel } from 'rsuite';

export const PanelStyle = css`
  padding: 2rem;
  margin-bottom: 1.3rem;
  margin-top: 1.3rem;
  border-radius: 1.1rem;
  background-color: ${theme.colors.surface};
  overflow: hidden; /* Required for height animations */
`;

export const SecondaryPanelStyle = css`
  ${PanelStyle}

  background-color: ${theme.colors.surfaceSecondary};
`;