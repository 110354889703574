import { Container, Panel } from "rsuite";
import React, { useEffect, useState } from 'react';

const Academy = () => {
	const [learning, setLearning] = useState();

    useEffect(()=>{
        const getLearning = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setLearning(res.data);
        }
        getLearning();
    }, []);

    return (
        <Container style={{ margin: "20px 0" }}>
            {
                learning && <Panel style={{ background: "#ffffff" }} bordered><div dangerouslySetInnerHTML={{ __html: learning.academy }} /></Panel>
            }
        </Container>
    );
};

export default Academy;
