import React from "react";
import { Container, Panel } from "rsuite";
import Footer from "./Footer";

const Dashboard = () => {
    return (
        <Container className="mx-20">
            <Panel className="panel-100">
                
            </Panel>
            <Footer/>
        </Container>
    );
}
export default Dashboard;