import React, { useState, useCallback } from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import theme from '../../styles/theme';
import { HeaderSmallest500Style, TextSmall400Style } from '../styles/TextStyles';
import ArrowDownLineIcon from '@rsuite/icons/ArrowDownLine';
import ArrowUpLineIcon from '@rsuite/icons/ArrowUpLine';

/* Utility function to extract text content from JSX elements
 * This function recursively traverses the element tree and collects all text nodes
 * @param {JSX.Element} element - The JSX element to extract text from
 * @returns {string[]} Array of extracted text strings
 */
const extractTextContent = (element) => {
    if (!element) return [];
    
    // Handle string/number literals
    if (typeof element === 'string' || typeof element === 'number') 
        return [String(element)];
    
    // Handle arrays (like children arrays)
    if (Array.isArray(element)) 
        return element.flatMap(extractTextContent);
    
    // Handle React elements
    if (React.isValidElement(element)) {
        // Extract text from children
        const childrenContent = extractTextContent(element.props.children);
        
        // Extract text from special props (like checkbox labels, button text, etc.)
        const propsContent = [];
        if (element.props.label) propsContent.push(element.props.label);
        if (element.props.title) propsContent.push(element.props.title);
        if (element.props.alt) propsContent.push(element.props.alt);
        
        return [...propsContent, ...childrenContent];
    }
    
    // Handle objects with nested content
    if (element && typeof element === 'object') {
        return Object.values(element).flatMap(extractTextContent);
    }
    
    return [];
};

/* Creates a searchable map of all grid content
 * Maps each cell's unique key to its searchable content
 * @param {Array} rows - Array of row data
 * @returns {Object} Map of cell keys to their searchable content
 */
const createSearchKeywords = (rows) => {
    const searchMap = {};
    
    rows.forEach((row, rowIdx) => {
        row.forEach((cell, cellIdx) => {
            const key = `cell-${rowIdx}-${cellIdx}`;
            const textContent = extractTextContent(cell);
            if (textContent.length > 0) {
                searchMap[key] = textContent.filter(Boolean); // Remove empty strings
            }
        });
    });
    
    return searchMap;
};

/* Creates a row-based structure from flat children array
 * @param {Array} children - Flat array of cell elements
 * @param {number} columns - Number of columns in the grid
 * @returns {Array} Array of row objects with metadata and cells
 */
const createRowStructure = (children, columns) => {
    const rows = [];
    const flatChildren = React.Children.toArray(children);

    for (let i = 0; i < flatChildren.length; i += columns) {
        const rowCells = flatChildren.slice(i, i + columns);
        const rowKeywords = rowCells.flatMap(cell => extractTextContent(cell));
        
        rows.push({
            id: `row-${i / columns}`,
            cells: rowCells,
            searchableContent: rowKeywords,
            isVisible: true, // Will be used later for filtering
        });
    }
    return rows;
};

/* Sorts the row structure based on a specific column
 * @param {Array} rows - Array of row objects
 * @param {number} columnIndex - Index of the column to sort by
 * @returns {Array} Sorted array of row objects
 */
const sortRowsByColumn = (rows, columnIndex) => {
    return [...rows].sort((rowA, rowB) => {
        const valueA = extractTextContent(rowA.cells[columnIndex])[0] || '';
        const valueB = extractTextContent(rowB.cells[columnIndex])[0] || '';
        
        // Try numeric sort first
        const numA = Number(valueA);
        const numB = Number(valueB);
        if (!isNaN(numA) && !isNaN(numB)) {
            return numA - numB;
        }
        
        // Fall back to string comparison
        return valueA.localeCompare(valueB);
    });
};

// Base grid container
const GridContainer = styled.div`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, auto)`};
  align-items: center;
  border-radius: 1rem;
  overflow: hidden;
  border: 1px solid ${theme.colors.backgroundDark};
  width: 100%;
  background-color: ${theme.colors.surfaceSecondary};
  row-gap: 1px;
`;

// Reusable cell content wrapper
const CellContent = styled.div`
  text-overflow: ellipsis;
  max-width: 100%;
  padding-right: clamp(0.5rem,2%,4rem);
  padding-left: clamp(0.5rem,2%,4rem);
  overflow: hidden;
`;

// Base cell styles using css prop
const CellStyle = css`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  border-bottom: 1px solid ${theme.colors.backgroundDark};
  border-top: 1px solid ${theme.colors.backgroundDark};

  & > * {
    margin-left: auto;
    margin-right: auto;
  }

  &:nth-child(${props => props.columns}n+1) {
    ${CellContent} {
      margin-left: 0;
      margin-right: auto;
      padding-left: 1.2rem;
    }
  }
`;

const SortIcon = styled.span`
  margin-left: 8px;
  opacity: 0.5;
  display: inline-flex;
  flex-direction: column;
  
  svg {
    font-size: 12px;
  }
`;

// Enhanced HeaderCell with hover and active states
const HeaderCell = styled.button`
  ${CellStyle};
  ${HeaderSmallest500Style};
  padding: 0.75rem;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  transition: background-color 0.2s ease;
  
  &:hover {
    background-color: ${theme.colors.backgroundDark};
  }

  &.active {
    background-color: ${theme.colors.backgroundTwilight};
    
    ${SortIcon} {
      opacity: 1;
    }
  }
`;

// Regular header cell for non-sortable columns
const RegularHeaderCell = styled.div`
  ${CellStyle};
  ${HeaderSmallest500Style};
  padding: 0.75rem;
  width: 100%;
`;

// Header and regular cell components
const Cell = styled.div`
  ${CellStyle};
  ${TextSmall400Style};
  background-color: ${theme.colors.surface};
`;

/* Styles for pagination controls */
const PaginationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  margin-top: 8rem;
`;

const PageButton = styled.button`
  ${HeaderSmallest500Style};
  padding: 0.5rem 1rem;
  background: none;
  border: 1px solid ${theme.colors.backgroundDark};
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover:not(:disabled) {
    background-color: ${theme.colors.backgroundDark};
  }

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
`;

const PageIndicator = styled.span`
  ${TextSmall400Style};
  padding: 0.5rem;
`;

/* Utility function to create page numbers array with ellipsis
 * @param {number} currentPage - Current active page (0-based)
 * @param {number} totalPages - Total number of pages
 * @returns {Array} Array of page numbers and ellipsis markers
 */
const getPageNumbers = (currentPage, totalPages) => {
    const delta = 2; // Number of pages to show before and after current page
    const range = [];
    const rangeWithDots = [];

    // Always show first page
    range.push(0);

    for (let i = currentPage - delta; i <= currentPage + delta; i++) {
        if (i > 0 && i < totalPages - 1) {
            range.push(i);
        }
    }

    // Always show last page
    if (totalPages > 1) {
        range.push(totalPages - 1);
    }

    // Sort and remove duplicates
    const uniqueRange = [...new Set(range)].sort((a, b) => a - b);

    // Add ellipsis where needed
    let prev = 0;
    for (const i of uniqueRange) {
        if (i - prev === 2) {
            rangeWithDots.push(prev + 1);
        } else if (i - prev !== 1) {
            rangeWithDots.push('...');
        }
        rangeWithDots.push(i);
        prev = i;
    }

    return rangeWithDots;
};

/* Styled number button component */
const NumberButton = styled(PageButton)`
    min-width: 2.5rem;
    padding: 0.5rem;
    
    &.active {
        background-color: ${theme.colors.backgroundDark};
    }
`;

/* Utility function to calculate relative column widths
 * This prevents column width "jumping" when data changes (sorting/pagination)
 * by calculating initial auto widths and fixing them as relative fr units
 * @param {HTMLElement} gridElement - The grid container element
 * @param {number} columns - Number of columns in the grid
 * @returns {string} CSS grid-template-columns value with fixed fr units
 */
const calculateColumnWidths = (gridElement, columns) => {
    if (!gridElement) return null;
    
    // Get all header cells (first row)
    const headerCells = Array.from(gridElement.children).slice(0, columns);
    
    // Calculate the total width of all columns
    const totalWidth = headerCells.reduce((sum, cell) => sum + cell.offsetWidth, 0);
    
    // Convert each column's width to a relative fraction
    const fractions = headerCells.map(cell => 
        ((cell.offsetWidth / totalWidth) * 100).toFixed(3) + 'fr'
    );
    
    return fractions.join(' ');
};

const CapiaGrid = ({ headers, children, maxDisplayedItems = 10 }) => {
    const columns = headers.length;
    const [activeSort, setActiveSort] = useState(null);
    const [sortDirection, setSortDirection] = useState('asc');
    const [currentPage, setCurrentPage] = useState(0);
    const [fixedColumnWidths, setFixedColumnWidths] = useState(null);
    
    // Reference to the grid container
    const gridRef = useCallback(node => {
        if (node !== null && !fixedColumnWidths) {
            // Calculate and set fixed column widths after initial render
            const widths = calculateColumnWidths(node, columns);
            setFixedColumnWidths(widths);
        }
    }, [columns, fixedColumnWidths]);

    // Create and store row structure
    const [rowStructure, setRowStructure] = useState(() => 
        createRowStructure(children, columns)
    );

    // Calculate pagination values
    const totalPages = Math.ceil(rowStructure.length / maxDisplayedItems);
    const startIndex = currentPage * maxDisplayedItems;
    const endIndex = startIndex + maxDisplayedItems;
    
    // Get current page rows
    const currentRows = rowStructure.slice(startIndex, endIndex);

    // Update row structure if children change
    React.useEffect(() => {
        setRowStructure(createRowStructure(children, columns));
        setCurrentPage(0); // Reset to first page when data changes
    }, [children, columns]);

    // Handle sort click with pagination reset
    const handleSort = (columnIndex) => {
        let newDirection;
        
        if (activeSort === columnIndex) {
            newDirection = sortDirection === 'asc' ? 'desc' : 'asc';
            setSortDirection(newDirection);
        } else {
            newDirection = 'asc';
            setActiveSort(columnIndex);
            setSortDirection(newDirection);
        }

        const sortedRows = sortRowsByColumn(rowStructure, columnIndex);
        setRowStructure(newDirection === 'asc' ? sortedRows : sortedRows.reverse());
        setCurrentPage(0); // Reset to first page after sorting
    };

    return (
        <>
            <GridContainer 
                ref={gridRef}
                columns={columns}
                style={fixedColumnWidths ? {
                    gridTemplateColumns: fixedColumnWidths
                } : undefined}
            >
                {/* Render headers */}
                {headers.map((header, idx) => (
                    header.isSortable ? (
                        <HeaderCell 
                            key={`header-${idx}`}
                            onClick={() => handleSort(idx)}
                            className={activeSort === idx ? 'active' : ''}
                        >
                            <CellContent>
                                {header.title}
                                <SortIcon>
                                    {activeSort === idx ? (
                                        sortDirection === 'asc' ? 
                                            <ArrowDownLineIcon /> : 
                                            <ArrowUpLineIcon />
                                    ) : (
                                        <ArrowDownLineIcon />
                                    )}
                                </SortIcon>
                            </CellContent>
                        </HeaderCell>
                    ) : (
                        <RegularHeaderCell key={`header-${idx}`}>
                            <CellContent>{header.title}</CellContent>
                        </RegularHeaderCell>
                    )
                ))}

                {/* Fixed row rendering logic */}
                {currentRows.map((row) => (
                    // Add React.Fragment with key to properly handle the row
                    <React.Fragment key={row.id}>
                        {row.isVisible && row.cells.map((cell, cellIdx) => (
                            <Cell key={`${row.id}-cell-${cellIdx}`}>
                                <CellContent>{cell}</CellContent>
                            </Cell>
                        ))}
                    </React.Fragment>
                ))}
            </GridContainer>

            {/* Only show pagination if there's data and more than one page */}
            {rowStructure.length > 0 && totalPages > 1 && (
                <PaginationContainer>
                    <PageButton 
                        onClick={() => setCurrentPage(p => p - 1)}
                        disabled={currentPage === 0}
                    >
                        Previous
                    </PageButton>
                    
                    {/* Render page numbers */}
                    {getPageNumbers(currentPage, totalPages).map((pageNum, idx) => (
                        pageNum === '...' ? (
                            <PageIndicator key={`ellipsis-${idx}`}>...</PageIndicator>
                        ) : (
                            <NumberButton
                                key={`page-${pageNum}`}
                                onClick={() => setCurrentPage(pageNum)}
                                className={currentPage === pageNum ? 'active' : ''}
                            >
                                {pageNum + 1}
                            </NumberButton>
                        )
                    ))}

                    <PageButton 
                        onClick={() => setCurrentPage(p => p + 1)}
                        disabled={currentPage >= totalPages - 1}
                    >
                        Next
                    </PageButton>
                </PaginationContainer>
            )}
        </>
    );
};

export default CapiaGrid;