import { faker } from '@faker-js/faker/locale/en';

// Utility to generate a random date string
const formatDate = () => new Date().toLocaleDateString();

// Mock user data
export function mockUsers(length) {
  return Array.from({ length }).map((_, rowIndex) => {
    return {
      id: rowIndex + 1,
      first_name: faker.person.firstName(),
      last_name: faker.person.lastName(),
      username: faker.person.username(),
      email: faker.internet.email(),
      phone: faker.phone.number(),
      avatar: `https://i.pravatar.cc/150`,
      roles: ["Admin", "Manager"],
    };
  });
}
// Mock client data
export function mockClients(length) {
  return Array.from({ length }).map((_, rowIndex) => ({
    id: rowIndex + 1,
    name: faker.company.name(),
    contact: faker.person.fullName(),
    kam: faker.person.fullName(),
    created_at: formatDate(),
    projects: rowIndex % 2 === 0 ? [faker.word.noun()] : Array.from({ length: 5 }, () => faker.word.noun()),
    status: rowIndex % 2 === 0 ? "Active" : "Inactive",
  }));
}

// Mock project data
export function mockProjects(length) {
  return Array.from({ length }).map((_, rowIndex) => ({
    id: rowIndex + 1,
    name: faker.word.noun(),
    client: faker.company.name(),
    created_at: new Date().toISOString(),
    solutions: rowIndex % 2 === 0 ? ["Tracker"] : ["Tracker", "ROMI"],
    status: rowIndex % 2 === 0 ? "Active" : "Inactive",
    kam: faker.person.fullName(),
  }));
}



// Mock FAQ data
export function mockFaq(length) {
  return Array.from({ length }).map((_, rowIndex) => ({
    id: rowIndex + 1,
    question: faker.lorem.sentence(),
    answer: faker.lorem.paragraph(),
    created_at: formatDate(),
    updated_at: formatDate(),
  }));
}

// Mock introductory content
export function mockIntro() {
  return {
    content: faker.lorem.paragraph(),
  };
}

// Mock driver data
export function mockDrivers(length) {
  return Array.from({ length }).map((_, rowIndex) => ({
    id: rowIndex + 1,
    name: faker.word.noun(),
    source: faker.company.name(),
    observations: faker.number.int({min:0, max:100}),
    units: faker.word.preposition(),
    quality_check: Math.floor(Math.random() * 3) + 1,
    created_at: formatDate(),
    updated_at: formatDate(),
  }));
}

// Mock hierarchical tree data
export function mockTreeData({ limits, labels, getRowData }) {
  const data = [];
  const generateTree = (list, parentValue = '', layer = 0) => {
    const length = limits[layer];
    const label = Array.isArray(labels) ? labels[layer] : labels;

    Array.from({ length }).forEach((_, index) => {
      const value = parentValue ? `${parentValue}-${index + 1}` : `${index + 1}`;
      const children = [];
      let row = {
        label: typeof label === 'function' ? label(layer, value, faker) : `${label} ${value}`,
        value,
      };

      if (getRowData) {
        row = { ...row, ...getRowData(layer, value) };
      }

      list.push(row);

      if (layer < limits.length - 1) {
        row.children = children;
        generateTree(children, value, layer + 1);
      }
    });
  };

  generateTree(data);
  return data;
}
