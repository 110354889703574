import React from 'react';
import { Button, Divider, HStack, Text } from 'rsuite';
import AddOutlineIcon from '@rsuite/icons/AddOutline';
import FileUploadIcon from '@rsuite/icons/FileUpload'; // Updated import

/**
 * Template component for Report subsections
 * @param {string} title - Section title
 * @param {string} overviewText - Grey text description below title
 * @param {ReactNode} children - Content to be rendered below divider
 */
const ReportSubSection = ({ title, overviewText, children }) => {
    return (
        <div >
            <div style={{ 
                padding: '20px 20px 5px 20px',
                display: 'flex', 
                justifyContent: 'space-between',
                alignItems: 'flex-start',
                marginBottom: '0px'
            }}>
                <div>
                    <Text size="xxl" style={{ display: 'block', marginBottom: '5px', fontWeight:'bold' }}>
                        {title}
                    </Text>
                    {overviewText && (
                        <Text style={{ color: '#666666' }}>
                            {overviewText}
                        </Text>
                    )}
                </div>
                <HStack spacing={10}>
                    <Button appearance="subtle" size="sm">
                        <AddOutlineIcon />
                    </Button>
                    <Button appearance="subtle" size="sm">
                        <FileUploadIcon /> {/* Updated icon */}
                    </Button>
                </HStack>
            </div>
            <Divider style={{ margin: '0px 0' }} />
            <div style={{ 
                padding: '20px 20px 30px 20px',
                }}>
                {children}
            </div>
        </div>
    );
};

export default ReportSubSection;