import { useEffect, useState } from "react";
import { Button, Col, Container, Form, Grid, IconButton, Message, Panel, Row, Schema, SelectPicker, Uploader } from "rsuite";
import StandardButton from "../ui_base/StandardButton";
import CloseOutline from '@rsuite/icons/CloseOutline';
import useStandardToaster from '../ui_base/StandardToaster';

const CreateProject = ({setRefresh, setShowCreateProject}) => {
    const [formData, setFormData] = useState({
        name: "",
        client_id: 0,
        kam_id: 0,
        project_id: "",
    });
    const [KAMs, setKAMs] = useState([]);
    const [clients, setClients] = useState([]);
    const [uploading, setUploading] = useState(false);
    const [preview, setPreview] = useState();
    const { pushToast } = useStandardToaster();

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        email: StringType().isEmail('Please enter a valid email address.')
    });

    useEffect(() => {
        const fetchData = async (url, setter) => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}${url}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            const res = await response.json();
            setter(res.data);
        };

        fetchData('/api/users/kams', setKAMs);
        fetchData('/api/clients', setClients);
    }, []);

    const handleInputChange = (key, value) => {
        setFormData(prevState => ({ ...prevState, [key]: value }));
    };

    const handleFileChange = async (files) => {
        if (!files || !files[0]) return;
        setUploading(true);
        setPreview(URL.createObjectURL(files[0].blobFile));
        setFormData(prevState => ({ ...prevState, logo: files[0].blobFile }));
        setUploading(false);
    }

    const handleRemoveFile = () => {
        if(preview){
            URL.revokeObjectURL(preview);
        }
        setPreview(null);
      };

    const handleSubmit = async() => {
        const formDataPayload = new FormData();
        Object.keys(formData).forEach(key => {
            formDataPayload.append(key, formData[key]);
        });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/`, { 
            headers: {
                // 'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: formDataPayload
        });
        if (response.status === 201) {
            setFormData({
                name: "",
                client_id: 0,
                kam_id: 0,
                project_id: ""
            });
            pushToast(<Message type="success">Project created successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            setShowCreateProject(false);
            setRefresh(new Date().getTime());
        } else {
            pushToast(<Message type="error">Failed to create project.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };  

    return (
        <Container style={{ margin: "10px" }}>
            <Panel header="Create new project" bordered>
                <Form model={model} onSubmit={handleSubmit}>
                    <Grid fluid>
                        <Row>
                            <Col md={16}>
                                <Form.Group controlId="name">
                                    <Form.ControlLabel>Project name</Form.ControlLabel>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={value => handleInputChange("name", value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="client">
                                    <Form.ControlLabel>Client</Form.ControlLabel>
                                    <SelectPicker
                                        searchable={false}
                                        cleanable={false}
                                        style={{ minWidth: 300 }}
                                        data={clients.map((elem, i) => ({
                                            key: i + 1,
                                            label: elem.name,
                                            value: elem.id
                                        }))}
                                        value={formData.client_id}
                                        onChange={value => handleInputChange("client_id", value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="kam">
                                    <Form.ControlLabel>KAM</Form.ControlLabel>
                                    <SelectPicker
                                        searchable={false}
                                        cleanable={false}
                                        style={{ minWidth: 300 }}
                                        data={KAMs.map((elem, i) => ({
                                            key: i + 1,
                                            label: elem.name,
                                            value: elem.id
                                        }))}
                                        value={formData.kam_id}
                                        onChange={value => handleInputChange("kam_id", value)}
                                    />
                                </Form.Group>
                                <Form.Group controlId="project_id">
                                    <Form.ControlLabel>Project ID</Form.ControlLabel>
                                    <Form.Control
                                        type="text"
                                        name="project_id"
                                        value={formData.project_id}
                                        onChange={value => handleInputChange("project_id", value)}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={8}>
                                <Panel header="logo" bordered>
                                    {
                                        preview && <div style={{ marginTop: '20px', position: 'relative' }}>
                                        <img
                                            src={preview}
                                            alt="project logo"
                                            style={{ maxWidth: '100%', maxHeight: '300px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}
                                        />
                                        <IconButton
                                            icon={<CloseOutline />}
                                            style={{
                                            position: 'absolute',
                                            top: '-10px',
                                            right: '-10px',
                                            backgroundColor: '#f44336',
                                            color: 'white',
                                            }}
                                            circle
                                            size="xs"
                                            onClick={handleRemoveFile}
                                        />
                                        </div>
                                    }
                                    {
                                        !preview && <div style={{ textAlign: "right" }}>
                                        <Uploader
                                            action=""
                                            autoUpload={false}
                                            fileListVisible={false}
                                            accept="image/*"
                                            onChange={handleFileChange}
                                            disabled={uploading}
                                            cleanable={false}
                                            multiple={false}
                                        >
                                            <StandardButton type="button" disabled={uploading}>
                                                {'Upload'}
                                            </StandardButton>
                                        </Uploader>
                                    </div>
                                    }
                                </Panel>
                            </Col>
                        </Row>
                    </Grid>
                    <div style={{ textAlign: "right", margin: "10px" }}>
                        <Form.Group>
                            <Button type="submit" appearance="primary" color="orange">Save project</Button>
                        </Form.Group>
                    </div>
                </Form>
                </Panel>
        </Container>
    );
};

export default CreateProject;
