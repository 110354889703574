import React from "react";
import { Button, Checkbox, Message, Table, Toggle } from "rsuite";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye, faRefresh } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import useStandardToaster from '../../ui_base/StandardToaster';

const { Column, HeaderCell, Cell } = Table;
const Solutions = () => {
    const [projectSolutions, setProjectSolutions] = useState([]);
    const navigate = useNavigate();
    const location = useLocation();
    const {id} = location.state || {};
    const [refresh, setRefresh] = useState(new Date().getTime());
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getProjectSolutions = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setProjectSolutions(res.data);
        }
        getProjectSolutions();
    },[id, refresh]);

    const handleToggleProjectSolution = async(solutionId)=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
        });
        if (response.status === 200) {
            await handleUpdateProjectSolution(solutionId, true);
        } else {
            handleCreateProjectSolution(solutionId);
        }
    }
    const handleCreateProjectSolution = async(solutionId)=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: JSON.stringify({solution_id: solutionId})
        });
        if (response.status === 201) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Solution added to project successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to add project solution.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }
    const handleUpdateProjectSolution = async(solutionId, status)=>{
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${solutionId}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({is_active: status})
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Solution access updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update solution access.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    const handleManage = (id, solutionId) => {
        navigate('/projects/'+id+'/solutions/'+solutionId, {state: {id, solutionId}});
    }

    const handleCheckboxChange = async(rowId, featureKey) => {
        const projectSolution = projectSolutions.find(elem=>elem.id===rowId);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}/solutions/${projectSolution.solution.id}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'PATCH',
            body: JSON.stringify({
                features: {
                    ...projectSolution.features,
                    [featureKey]: !projectSolution.features[featureKey],
                }
            })
        });
        if (response.status === 200) {
            setRefresh(new Date().getTime());
            pushToast(<Message type="success">Solution features updated successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to update solution features.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };

    return (
       <Table height={400} autoHeight data={projectSolutions} bordered>
            <Column flexGrow={2}>
                <HeaderCell></HeaderCell>
                <Cell>{rowData=><Toggle color="orange" disabled={!rowData.solution.is_active} checked={rowData.is_active} onChange={async() => {
                    if(!rowData.is_active){
                        await handleToggleProjectSolution(rowData.solution.id)
                    } else {
                        await handleUpdateProjectSolution(rowData.solution.id, !rowData.is_active);
                    }
                }}>{rowData.solution.name}</Toggle>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Variables</HeaderCell>
                <Cell>{rowData=>rowData.variables}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Models</HeaderCell>
                <Cell>{rowData=>rowData.models}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Saved</HeaderCell>
                <Cell>{rowData=>rowData.saved}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Scenario</HeaderCell>
                <Cell>{rowData=><Toggle disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.scenario_access} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "scenario_access"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>AI+</HeaderCell>
                <Cell>{rowData=><Checkbox disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.ai} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "ai"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>KPI+</HeaderCell>
                <Cell>{rowData=><Checkbox disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.kpi} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "kpi"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Segment+</HeaderCell>
                <Cell>{rowData=><Checkbox disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.segment} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "segment"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Geo+</HeaderCell>
                <Cell>{rowData=><Checkbox disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.geo} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "geo"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={1}>
                <HeaderCell>Report+</HeaderCell>
                <Cell>{rowData=><Checkbox disabled={!rowData.solution.is_active || !rowData.is_active} checked={rowData.is_active && rowData?.features?.report} onChange={() =>
                                handleCheckboxChange(
                                    rowData.id,
                                    "report"
                                )
                            } color="orange"/>}</Cell>
            </Column>
            <Column flexGrow={2}>
                <HeaderCell>View/History/Configure</HeaderCell>
                <Cell>{(rowData)=>
                    <>
                        <Button key={`view_${rowData.id}`} size="sm" appearance="subtle"><FontAwesomeIcon icon={faEye}/></Button>
                        <Button key={`history_${rowData.id}`} size="sm" appearance="subtle"><FontAwesomeIcon icon={faRefresh}/></Button>
                        <Button key={`config_${rowData.id}`} size="sm" appearance="subtle" onClick={()=>handleManage(id, rowData.id)}><FontAwesomeIcon icon={faEdit}/></Button>
                    </>
                }</Cell>
            </Column>
       </Table>
    );
}
export default Solutions;
