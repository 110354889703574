import React, { createContext, useContext, useState, useRef } from 'react';
import { Button, Checkbox, HStack, Popover, Text, Whisper } from 'rsuite';
import CollaspedOutlineIcon from '@rsuite/icons/CollaspedOutline';
import ExpandOutlineIcon from '@rsuite/icons/ExpandOutline';
import HelpOutlineIcon from '@rsuite/icons/HelpOutline';
import styled from '@emotion/styled';

const AccordionContext = createContext();

const AccordionContainer = styled.div`
  border: none;
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  gap: 45px;
`;

const PanelContainer = styled.div`
  border: 1px solid #e5e5ea;
  border-radius: 8px;
  overflow: hidden;
  background-color: white;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
`;

const PanelHeader = styled.div`
  padding: 20px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f4ebff;
  border-bottom: ${({ isActive }) => (isActive ? '1px solid #e5e5ea' : 'none')};
`;

const PanelContent = styled.div`
  max-height: ${({ isActive }) => (isActive ? '500px' : '0')};
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  background-color: white;
`;

const CapiaAccordion = ({ children, defaultActiveKey = null }) => {
  const [activeKeys, setActiveKeys] = useState(() => {
    const childrenArray = React.Children.toArray(children);
    return childrenArray.map((_, index) => index);
  });

  return (
    <AccordionContext.Provider value={{ activeKeys, setActiveKeys }}>
      <AccordionContainer>
        {children}
      </AccordionContainer>
    </AccordionContext.Provider>
  );
};

const Panel = ({ eventKey, header, children, helpText }) => {
  const { activeKeys, setActiveKeys } = useContext(AccordionContext);
  const [isChecked, setIsChecked] = useState(true);
  const triggerRef = useRef();
  const isActive = activeKeys.includes(eventKey);

  const helpPopover = (
    <Popover style={{ maxWidth: '300px' }}>
      <div style={{ padding: '10px' }}>
        <Text style={{ fontWeight: 'bold', marginBottom: '8px' }}>
          Help - {header}
        </Text>
        <div>
          {helpText || (
            <p>This section contains important information about {header}.</p>
          )}
        </div>
      </div>
    </Popover>
  );

  const togglePanel = () => {
    setActiveKeys(prev =>
      isActive
        ? prev.filter(key => key !== eventKey)
        : [...prev, eventKey]
    );
  };

  return (
    <PanelContainer>
      <PanelHeader isActive={isActive}>
        <Text
          size="lg"
          style={{
            fontWeight: 900,
            cursor: 'pointer',
            fontSize: '1.6em'
          }}
          onClick={togglePanel}
        >
          {header}
        </Text>
        <HStack spacing={0}>
          <Checkbox
            checked={isChecked}
            onChange={(_, checked) => setIsChecked(checked)}
            color="green"
          />
          <Whisper
            trigger="click"
            speaker={helpPopover}
            placement="bottomEnd"
            preventOverflow
          >
            <Button
              appearance="subtle"
              size="sm"
            >
              <HelpOutlineIcon />
            </Button>
          </Whisper>
          <Button appearance="subtle" size="sm" onClick={togglePanel}>
            {isActive ? <ExpandOutlineIcon /> : <CollaspedOutlineIcon />}
          </Button>
        </HStack>
      </PanelHeader>
      <PanelContent isActive={isActive}>
        {children}
      </PanelContent>
    </PanelContainer>
  );
};

CapiaAccordion.Panel = Panel;
export default CapiaAccordion;
