import React from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Grid, Row, Text } from "rsuite";

const ClientSettings = ({client}) => {
    return (
        <Grid fluid>
            { client && 
                <Row>
                    <Col md={8}>
                        <table>
                                <tbody>
                                    <tr>
                                        <td width={100}>Client name:</td>
                                        <td>{client.name}</td>
                                    </tr>
                                    <tr>
                                        <td>Client ID:</td>
                                        <td>{client.client_id}</td>
                                    </tr>
                                    <tr>
                                        <td>KAM:</td>
                                        <td>{client.kam}</td>
                                    </tr>
                                </tbody>
                            </table>
                    </Col>
                    <Col md={8}>
                            <table>
                                <tbody>
                                    <tr>
                                        <td width={100}>Contact:</td>
                                        <td>{client.contact.name}</td>
                                    </tr>
                                    <tr>
                                        <td>E-mail:</td>
                                        <td>{client.contact.email}</td>
                                    </tr>
                                    <tr>
                                        <td>Phone:</td>
                                        <td>{client.contact.phone}</td>
                                    </tr>
                                </tbody>
                            </table>
                    </Col>
                    <Col md={8}>
                        <Text size="xl">Logo</Text>
                        <div style={{ textAlign: "center" }}>
                            <img src={`${process.env.REACT_APP_API_URL}/${client.logo}`} width={150} alt="client logo"/>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button appearance="subtle"><FontAwesomeIcon icon={faEdit}/></Button>
                        </div>
                    </Col>
                </Row>
            }
        </Grid>
    );
}
export default ClientSettings;