import { useState } from "react";
import { Button, Input, RadioTile, RadioTileGroup, Text, useMediaQuery } from "rsuite";
import chatgpt_icon from '../../assets/chatgpt-icon.png';
import claude_icon from '../../assets/claude-ai-icon.png';
import gemini_icon from '../../assets/google-gemini-icon.png';

const AISettings = () => {
    const [LLM, setLLM] = useState('chatgpt');
    const [personality, setPersonality] = useState('chatgpt');
    console.log(LLM, personality);
    return (
       <div>
            <Text weight="bold">Select LLM</Text>
            <RadioTileGroup defaultValue="chatgpt" inline={useMediaQuery('md')}>
                <RadioTile value="chatgpt" label="ChatGPT 4.0" onClick={()=>setLLM("chatgpt")} icon={<img src={chatgpt_icon} alt="chatgpt icon"/>} style={{ width: "33%"}}>
                    This is what it's good for and how you can use it.
                </RadioTile>
                    <RadioTile value="claude" label="Claude" onClick={()=>setLLM("claude")} icon={<img src={claude_icon} alt="claude icon"/>} style={{ width: "33%"}}>
                    This is what it's good for and how you can use it.
                </RadioTile>
                <RadioTile value="gemini" label="Gemini" onClick={()=>setLLM("gemini")} icon={<img src={gemini_icon} alt="gemini icon"/>} style={{ width: "33%"}}>
                    This is what it's good for and how you can use it.
                </RadioTile>
            </RadioTileGroup>
            <br/>
            <br/>
            <Text weight="bold">Select personality</Text>
            <RadioTileGroup defaultValue="p1" inline={useMediaQuery('md')}>
                <RadioTile value="p1" label="Brief and to-the-point" onClick={()=>setPersonality("p1")} style={{ width: "33%"}}>
                    Professional and efficient. Prefers bullet points and easy-to-digest summaries.
                </RadioTile>
                    <RadioTile value="p2" label="Analytical and comprehensive" onClick={()=>setPersonality("p2")} style={{ width: "33%"}}>
                    Prefers full paragraphs. Emphasizes reasoning and explanations.
                </RadioTile>
                <RadioTile value="p3" label="Analytical and entrepreneurial" onClick={()=>setPersonality("p3")} style={{ width: "33%"}}>
                    Combines full paragraphs and bullet points. Emphasizes trends, opportunities and ideas.
                </RadioTile>
            </RadioTileGroup>
            <div style={{ margin: "30px 0"}}>
                <Text weight="bold">Strategic context: What do you want to achieve and how?</Text>
                <Input as="textarea" rows={3} placeholder="Enter comment" />
            </div>
            <div style={{ margin: "30px 0"}}>
                <Text weight="bold">Project context: Tell us</Text>
                <Input as="textarea" rows={3} placeholder="Enter comment" />
            </div>
            <div style={{ margin: "30px 0"}}>
                <Text weight="bold">Company context: Tell us</Text>
                <Input as="textarea" rows={3} placeholder="Enter comment" />
            </div>
            <div style={{ margin: "30px 0"}}>
                <Text weight="bold">Industry context: What are the key features, drivers and trends of the industry?</Text>
                <Input as="textarea" rows={3} placeholder="Enter comment" />
            </div>
            <div style={{ margin: "30px 0"}}>
                <Text weight="bold">Competitive context: Who and what do you compete against, and what is the current market situation?</Text>
                <Input as="textarea" rows={3} placeholder="Enter comment" />
            </div>
            <div style={{ textAlign: "right" }}>
                <Button appearance="primary" color="orange">Save settings</Button>
            </div>
       </div>
    );
}
export default AISettings;
