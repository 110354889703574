import React, { useEffect, useState } from "react";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Grid, Message, Row, SelectPicker, Text } from "rsuite";
import useStandardToaster from '../../ui_base/StandardToaster';

const Settings = ({project}) => {
    const [KAMs, setKAMs] = useState([]);
    const [KAM, setKAM] = useState("");
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getKAMs = async() => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/kams`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setKAMs(res.data);
        }
        getKAMs();
        setKAM(project?.kam?.id);
    },[project]);

    const handleUpdateProject = async(id, kamId)=>{
        setKAM(kamId);
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/${id}`, { 
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${localStorage.getItem('token')}`
            },
            method: 'PATCH',
            body: JSON.stringify({ kam_id: kamId }),
        });
        if (response.status === 200) {
            pushToast(<Message type="success">KAM changed successfully.</Message>, { placement: 'topCenter', duration: 5000 });
        } else {
            pushToast(<Message type="error">Failed to change KAM.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    }

    return (
        <Grid fluid>
            {
                project &&
                <Row>
                    <Col md={8}>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="50%">Project name:</td>
                                    <td width="40%">{project.name}</td>
                                    <td width="10%"><Button appearance="subtle"><FontAwesomeIcon icon={faEdit}/></Button></td>
                                </tr>
                                <tr>
                                    <td width="50%">Client:</td>
                                    <td width="40%">{project.client.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Project ID:</td>
                                    <td width="40%">{project.project_id}</td>
                                    <td width="10%"><Button appearance="subtle"><FontAwesomeIcon icon={faEdit}/></Button></td>
                                </tr>
                                <tr>
                                    <td>KAM:</td>
                                    <td colSpan={2}>
                                        {
                                            KAMs && KAM && <SelectPicker searchable={false} cleanable={false} size="sm" value={KAM} data={
                                                KAMs.map((elem,i)=> {
                                                    return { key: i+1, label: elem.name, value: elem.id }
                                                })
                                            } onChange={(value)=>{handleUpdateProject(project.id, value);}} style={{ width: 250 }}/>
                                        }
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={8}>
                        <table>
                            <tbody>
                                <tr>
                                    <td width="50%">Contact:</td>
                                    <td width="50%">{project.client.contact.name}</td>
                                </tr>
                                <tr>
                                    <td width="50%">E-mail:</td>
                                    <td width="50%">{project.client.contact.email}</td>
                                </tr>
                                <tr>
                                    <td width="50%">Phone:</td>
                                    <td width="50%">{project.client.contact.phone}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Col>
                    <Col md={8}>
                        <Text size="xl">Logo</Text>
                        <div style={{ textAlign: "center" }}>
                            <img src={`${process.env.REACT_APP_API_URL}/${project.logo}`} width={150} alt="project logo"/>
                        </div>
                        <div style={{ textAlign: "right" }}>
                            <Button appearance="subtle"><FontAwesomeIcon icon={faEdit}/></Button>
                        </div>
                    </Col>
                </Row>
            }
        </Grid>
    );
}
export default Settings;