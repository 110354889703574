import { Button, Col, Grid, Message, Modal, Row, Text, Toggle } from "rsuite";
import WarningRoundIcon from '@rsuite/icons/WarningRound';
import TrashIcon from '@rsuite/icons/Trash';
import RemindOutlineIcon from '@rsuite/icons/RemindOutline';
import { useState } from "react";

const DeactivateClient = () => {
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Grid fluid>
            <Row gutter={50}>
                <Col md={8}>
                    <Message showIcon type="warning" header="Danger Zone">Please be very careful and very sure before making changes.</Message>
                </Col>
                <Col md={8}>
                    <Toggle checked={true} color="orange"> Preserve access</Toggle>
                    <Text size="sm" style={{ margin: "10px" }}>Allow active users to maintain access to saved reports.</Text> 
                </Col>
                <Col md={4}>
                    <Button appearance="primary" color="orange" startIcon={<WarningRoundIcon/>}>Deactivate</Button>
                </Col>
                <Col md={4}>
                    <Button appearance="ghost" color="red" startIcon={<TrashIcon/>} onClick={handleOpen}>Delete</Button>
                </Col>
            </Row>
            <Modal backdrop={true} keyboard={false} open={open} onClose={handleClose}>
                <Modal.Header><RemindOutlineIcon color="red"/></Modal.Header>
                <Modal.Body>
                    Are you sure to delete?
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={handleClose} appearance="primary" color="green">
                        Yes
                    </Button>
                    <Button onClick={handleClose} appearance="subtle" color="red">
                        No
                    </Button>
                </Modal.Footer>
            </Modal>
        </Grid>
    );
}
export default DeactivateClient;
