import { Button, Checkbox, Table, Text } from "rsuite";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

const { Column, HeaderCell, Cell } = Table;
const Drivers = () => {
    const [data] = useState([
        {
            id: 1,
            name: 'Salg_total',
            source: 'Coop',
            required_driver: true,
            last_update: new Date().toLocaleDateString(),
            observations: 36,
            quality_check: false,
        }
    ]);

    return (
       <div>
            <div>
                <Text>Available drivers: 21</Text>
            </div>
            <Table data={data} bordered>
                <Column flexGrow={2}>
                    <HeaderCell><Checkbox>Select all/none</Checkbox></HeaderCell>
                    <Cell>{rowData=><Checkbox color="orange">{rowData.name}</Checkbox>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Source</HeaderCell>
                    <Cell>{rowData=>rowData.source}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Required driver</HeaderCell>
                    <Cell>{rowData=><Checkbox color="orange">{rowData.required_driver}</Checkbox>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Last Update</HeaderCell>
                    <Cell>{rowData=>rowData.last_update}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Observations</HeaderCell>
                    <Cell>{rowData=>rowData.observations}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>Quality check</HeaderCell>
                    <Cell>{rowData=><Checkbox checked={rowData.quality_check} color="orange"/>}</Cell>
                </Column>
                <Column flexGrow={1}>
                    <HeaderCell>View</HeaderCell>
                    <Cell>{rowData=><Button size="sm" appearance="subtle"><FontAwesomeIcon icon={faEye}/></Button>}</Cell>
                </Column>
            </Table>
       </div>
    );
}
export default Drivers;
