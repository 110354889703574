import React, { useState, useRef, useEffect } from 'react';
import styled from '@emotion/styled';
import profileSymbol from '../../assets/icons/profile.svg';
import { DropdownContainer, DropdownMenu, DropdownItem, dropdownContainer, dropdownMenu, dropdownItem } from '../styles/DropdownButtonStyles';
import { MenuItem } from './DropdownButton';
import LineDivider from './LineDivider';
import AvatarIconStyle from '../styles/AvatarIconStyle';
import CircleButtonStyle from '../styles/CircleButtonStyle';
const Button = styled.button`
    ${CircleButtonStyle};
`;


const DropdownButtonUser = ({ children }) => {
    const [isOpen, setIsOpen] = useState(false);
    const dropdownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    return (
        <div css={dropdownContainer} ref={dropdownRef}>
            <Button onClick={()=>setIsOpen(!isOpen)} className={isOpen ? 'open' : ''}>
                <div css={AvatarIconStyle}/>
            </Button>
            <div css={dropdownMenu} className={isOpen ? 'open' : ''}>
                {React.Children.map(children, child => 
                    child ? React.cloneElement(child, { setIsOpen }) : null
                )}
            </div>
        </div>
    );
};

export default DropdownButtonUser;


