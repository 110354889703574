import { Accordion, Container } from "rsuite";
import { useEffect, useState } from "react";

const ListFAQ = () => {
    const [faqData, setFaqData] = useState([]);

    useEffect(()=>{
        const getLearning = async () =>{
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/learnings`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            const learning = res.data;
            setFaqData(learning.faq);
        }
        getLearning();
    }, []);
    return (
        <Container style={{ margin: "20px 0"}}>
            <Accordion bordered defaultActiveKey={0}>
            {
                faqData.map((elem,i)=>{
                    return (<Accordion.Panel header={elem.question} eventKey={i}>{elem.answer}</Accordion.Panel>)
                })
            }
            </Accordion>
        </Container>
    );
}
export default ListFAQ;