import React from "react";
import { Panel } from "rsuite";
import opinion_logo from '../assets/logo_xl.png';
import { PanelSecondary } from "./ui_base/PanelSecondary";

const Footer = () => {
    return (
        <PanelSecondary >
            <div style={{ textAlign: 'right' }}>
                <img src={opinion_logo} alt="logo" width={280}/>
            </div>
        </PanelSecondary>
    );
}
export default Footer;