import React from 'react';
import { useAuth } from '../context/AuthContext';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAdd, faArrowTrendUp, faBars, faBookOpen, faBookReader, faBuilding, faDatabase, faEdit, faFile, faHistory, faList, faUser, faMagnifyingGlassArrowRight, faPlay, faProjectDiagram, faQuestion, faSignOut, faUserAlt, faUsers } from '@fortawesome/free-solid-svg-icons';
import { faCircleQuestion } from '@fortawesome/free-regular-svg-icons'; // Import from regular icons
import { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import citrusAppLogo from '../assets/citrus-logo.png';
import CompanyUserLogo from '../assets/coop.png';
import bellSymbol from '../assets/icons/bell.svg';
import DropdownButton, { MenuItem } from './ui_base/DropdownButton';
import DropdownButtonUser from './ui_base/DropdownButtonUser';
import LineDivider from './ui_base/LineDivider';
import AvatarIconStyle from './styles/AvatarIconStyle';
import CircleButtonStyle from './styles/CircleButtonStyle';
import theme from '../styles/theme';
import { Avatar, Radio, RadioGroup } from 'rsuite';

const Navigation = () => {
    
    const WhiteNavbar = styled('div')`
        background-color: white ;
        border-bottom: 1px solid #e5e5e5;
        padding: 0.5rem;
        //rounded corners
        border-radius: 1.2rem;
        //drop shadow
        box-shadow: 0 0 10px rgba(0,0,0,0.3);
        display: flex;
        flex-direction: row;
        align-items: stretch;
        margin-bottom: 1rem;
    `;
    const LeftAlignedContainer = styled.div`
        flex-grow: 1;
        display: flex;
        width: auto;
        gap: 1rem;
        align-items: center;
        justify-content: center;

    `;
    const MiddleAlignedContainer = styled.div`
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        align-items: center;
        width: auto;
        gap: 1rem;
    `;

    const RightAlignedContainer = styled.div`
        flex-grow: 1;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: auto;
        gap: 1rem;
    `;

    // A Emotion css class for the logo in the navbar which
    // uses background image set to cover and a fixed height
    const AppLogo = styled(Link)`
        background-image: url(${citrusAppLogo});
        background-size: cover;
        height: 1.66rem;
        width: 6.7rem;
        cursor: pointer;
        
    `;


    const IconButton = styled.button`
        ${CircleButtonStyle}
        CircleButton
    `;

    const UserLogoComp = styled.div`
        background-image: url(${CompanyUserLogo});
        background-size: cover;
        height: 2.6rem;
        width: 4.53rem;
        position: relative;
        //top: -0.2rem;

    `;

    const UserProfile = styled.div`
        display: flex;
        align-items: center;
        gap: 0.5rem;
    `;

    const UserInfo = styled.div`
        display: flex;
        flex-direction: column;
        margin-left: 0.5rem;
    `;

    const UserName = styled.div`
    font-size: 0.8rem;
        font-weight: bold;
    `;

    const UserEmail = styled.div`
        font-size: 0.74rem;
        color: #6b7280;
    `;

    const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
         margin-right: 0.4rem;
    `;

    const location = useLocation();
    const currentPath = location.pathname;
    const { isAuthenticated, user, hasRole } = useAuth();

    const userName = user?.name || "User name";
    const userEmail = user?.email || "Email";

    const [projects, setProjects] = useState([]);
    const [selectedProject, setSelectedProject] = useState();

    useEffect(()=>{
        if(user){
            const getProjects = async () =>{
                const response = await fetch(`${process.env.REACT_APP_API_URL}/api/projects/?user=${user.id}`, { headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }});
                const res = await response.json();
                setProjects(res.data);
            }
            getProjects();
        }
    },[user]);

    return (
        <WhiteNavbar className='navbar'>
            <LeftAlignedContainer>
                 <AppLogo to="/dashboard" className="AppLogo" />
            </LeftAlignedContainer>
            {
                isAuthenticated && 
                <MiddleAlignedContainer defaultActiveKey="dashboard" appearance='default'>
                    
                    <DropdownButton title="Insights">
                        <MenuItem as={Link} to="/insights/trending"><StyledFontAwesomeIcon icon={faArrowTrendUp}/> Trending stories</MenuItem>
                        <MenuItem as={Link} to="/insights/explorer"><StyledFontAwesomeIcon icon={faMagnifyingGlassArrowRight}/> Insight explorer</MenuItem>
                        <MenuItem as={Link} to="/insights/data"><StyledFontAwesomeIcon icon={faDatabase}/> Data explorer</MenuItem>
                        <MenuItem as={Link} to="/insights/reports"><StyledFontAwesomeIcon icon={faFile}/> My reports</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/insights/feed"><StyledFontAwesomeIcon icon={faBars}/> Insight Feed</MenuItem>
                    </DropdownButton>

                    <DropdownButton title="Analyze">
                        <MenuItem as={Link} to="/models/new"><StyledFontAwesomeIcon icon={faAdd}/> New Model</MenuItem>
                        <MenuItem as={Link} to="/models"><StyledFontAwesomeIcon icon={faList}/> My models</MenuItem>
                        <MenuItem as={Link} to="/models/history"><StyledFontAwesomeIcon icon={faHistory}/> Model history</MenuItem>
                        
                        {hasRole(["owner", "admin", "manager"]) && (
                            <>
                                <LineDivider /> 
                                <MenuItem as={Link} to="/datasets"><StyledFontAwesomeIcon icon={faEdit}/> Manage drivers</MenuItem>
                            </>
                        )}
                    </DropdownButton>

                    <DropdownButton title="Scenario">
                        <MenuItem as={Link} to="/scenarios/create"><StyledFontAwesomeIcon icon={faAdd}/> Create scenario</MenuItem>
                        <MenuItem as={Link} to="/scenarios"><StyledFontAwesomeIcon icon={faList}/> My scenarios</MenuItem>
                        <MenuItem as={Link} to="/scenarios/history"><StyledFontAwesomeIcon icon={faHistory}/> Scenario history</MenuItem>
                    </DropdownButton>

                    <DropdownButton title="Learn">
                        <MenuItem as={Link} to="/intro" className={currentPath === "/intro" ? "active" : ""}><StyledFontAwesomeIcon icon={faPlay}/> Quick-start intro</MenuItem>
                        <MenuItem as={Link} to="/academy" className={currentPath === "/academy" ? "active" : ""}><StyledFontAwesomeIcon icon={faBuilding}/> OKAI academy</MenuItem>
                        <MenuItem as={Link} to="/faq" className={currentPath === "/faq" ? "active" : ""}><StyledFontAwesomeIcon icon={faQuestion}/> FAQ</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/company-training"><StyledFontAwesomeIcon icon={faBookOpen}/> Company training</MenuItem>
                    </DropdownButton>

                    <DropdownButton title="Manage">
                        <MenuItem as={Link} to="/projects" className={currentPath === "/projects" ? "active" : ""}><StyledFontAwesomeIcon icon={faProjectDiagram}/> Manage projects</MenuItem>
                        <MenuItem as={Link} to="/clients" className={currentPath === "/clients" ? "active" : ""}><StyledFontAwesomeIcon icon={faUserAlt}/> Manage clients</MenuItem>
                        <LineDivider /> 
                        <MenuItem as={Link} to="/learning" className={currentPath.startsWith("/learning") ? "active" : ""}><StyledFontAwesomeIcon icon={faBookReader}/> Manage learning</MenuItem>
                    </DropdownButton>
                </MiddleAlignedContainer>
            }
            {isAuthenticated && user && (
                <RightAlignedContainer>
                    {
                        selectedProject && <Avatar src={`${process.env.REACT_APP_API_URL}/${selectedProject.logo}`}/>
                    }
                    <IconButton>
                        <FontAwesomeIcon color={theme.colors.primary} size="sm" icon={faCircleQuestion} alt="help"/> {/* Use the regular icon */}
                    </IconButton>
                    <IconButton>
                        <img src={bellSymbol} alt="Notifications" />
                    </IconButton>
                    <DropdownButtonUser>
                        <MenuItem>
                            <UserProfile>
                                <div css={AvatarIconStyle} />
                                <UserInfo>
                                    <UserName>{userName}</UserName>
                                    <UserEmail>{userEmail}</UserEmail>
                                </UserInfo>
                            </UserProfile>
                        </MenuItem>
                        <LineDivider />
                        <MenuItem as={Link} to="/profile" className={currentPath === "/profile" ? "active" : ""}>
                            <StyledFontAwesomeIcon icon={faUser}/> Profile settings
                        </MenuItem>
                        <MenuItem as={Link} >
                            <StyledFontAwesomeIcon  icon={faCircleQuestion}/> Help 
                        </MenuItem>
                        <LineDivider/>
                        {
                            projects && projects.length > 0 && <>
                                <MenuItem>Select project:</MenuItem>
                                    <div>
                                        <RadioGroup name="radio-group" value={selectedProject?.id} onChange={(value)=>setSelectedProject(projects.find(elem=>elem.id===value))}>
                                        {
                                            projects.map(project=>{
                                                return (<Radio value={project.id} checked={project.id===selectedProject?.id}>{project.name}</Radio>)
                                            })
                                        }
                                        </RadioGroup>
                                    </div>
                            </>
                        }
                        <LineDivider />
                        <MenuItem as={Link} to="/teams" className={currentPath === "/teams" ? "active" : ""}>
                            <StyledFontAwesomeIcon icon={faUsers}/> Manage teams
                        </MenuItem>
                        <LineDivider />
                        <MenuItem as={Link} to="/logout"><StyledFontAwesomeIcon icon={faSignOut}/> Log out</MenuItem>
                    </DropdownButtonUser>
                </RightAlignedContainer>
            )}
            {!isAuthenticated && <MenuItem as={Link} to="/login">Login</MenuItem>}
        </WhiteNavbar>
    );
}
export default Navigation;