import styled from '@emotion/styled';


const PanelIndentSection = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  margin-left: 4rem;
  margin-right: 4rem;
`;

export { PanelIndentSection };