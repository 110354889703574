import { useEffect, useState } from "react";
import { Button, Col, Form, Grid, IconButton, Message, Panel, Row, Schema, SelectPicker, Uploader } from "rsuite";
import StandardButton from "../ui_base/StandardButton";
import CloseOutline from '@rsuite/icons/CloseOutline';
import useStandardToaster from '../ui_base/StandardToaster';
import FormGroup from "rsuite/esm/FormGroup";

const CreateClient = ({setRefresh, setShowCreateClient}) => {
    const [KAMs, setKAMs] = useState([]);
    const [formData, setFormData] = useState({
        name: "",
        kam_id: 0,
        client_id: "",
    });
    const [uploading, setUploading] = useState(false);

    const [preview, setPreview] = useState();
    const { pushToast } = useStandardToaster();

    useEffect(()=>{
        const getKAMs = async() => {
            const response = await fetch(`${process.env.REACT_APP_API_URL}/api/users/kams`, { headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`
            }});
            const res = await response.json();
            setKAMs(res.data);
        }
        getKAMs();
    }, []);

    const { StringType } = Schema.Types;

    const model = Schema.Model({
        name: StringType().isRequired('This field is required.'),
        clientId: StringType().isEmail('Please enter a valid client ID.'),
        kam: StringType().isEmail('Please select a KAM.')
    });

    const handleInputChange = (key, value) => {
        setFormData(prevState => ({ ...prevState, [key]: value }));
    };

    const handleFileChange = async (files) => {
        if (!files || !files[0]) return;
        setUploading(true);
        setPreview(URL.createObjectURL(files[0].blobFile));
        setFormData(prevState => ({ ...prevState, logo: files[0].blobFile }));
        setUploading(false);
    }

    const handleRemoveFile = () => {
        if(preview){
            URL.revokeObjectURL(preview);
        }
        setPreview(null);
    };

    const handleSubmit = async() => {
        const formDataPayload = new FormData();
        Object.keys(formData).forEach(key => {
            formDataPayload.append(key, formData[key]);
        });
        const response = await fetch(`${process.env.REACT_APP_API_URL}/api/clients/`, { 
            headers: {
                Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            method: 'POST',
            body: formDataPayload
        });
        if (response.status === 201) {
            setFormData({
                name: "",
                kam_id: 0,
                client_id: "",
            });
            pushToast(<Message type="success">Client created successfully.</Message>, { placement: 'topCenter', duration: 5000 });
            setShowCreateClient(false);
            setRefresh(new Date().getTime());
        } else {
            pushToast(<Message type="error">Failed to create project.</Message>, { placement: 'topCenter', duration: 5000 });
        }
    };  

    return (
        <Panel bordered className="mt-20" style={{ backgroundColor: "#ffffff"}}>
            <Form model={model} onSubmit={handleSubmit}>
                <Grid fluid>
                    <Row>
                        <Col md={16}>
                            <Form.Group controlId="name">
                                <Form.ControlLabel>Client name</Form.ControlLabel>
                                <Form.Control type="text" name="name" value={formData.name} onChange={value=>handleInputChange('name', value)}/>
                            </Form.Group>
                            <Form.Group controlId="project_id">
                                <Form.ControlLabel>Client ID</Form.ControlLabel>
                                <Form.Control type="text" name="client_id" value={formData.client_id} onChange={value=>handleInputChange('client_id', value)}/>
                            </Form.Group>
                            <Form.Group controlId="kam">
                                <Form.ControlLabel>KAM</Form.ControlLabel>
                                <SelectPicker searchable={false} cleanable={false} style={{minWidth: 300}} data={
                                    KAMs.map((elem,i)=> {
                                        return { key:i+1, label: elem.name, value: elem.id }
                                    })} value={formData.kam_id} onChange={value=>handleInputChange('kam_id', value)}></SelectPicker>
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            <Panel header="Client logo" bordered>
                            {
                                preview && <div style={{ marginTop: '20px', position: 'relative' }}>
                                <img
                                    src={preview}
                                    alt="project logo"
                                    style={{ maxWidth: '100%', maxHeight: '300px', border: '1px solid #ddd', padding: '10px', borderRadius: '5px' }}
                                />
                                <IconButton
                                    icon={<CloseOutline />}
                                    style={{
                                    position: 'absolute',
                                    top: '-10px',
                                    right: '-10px',
                                    backgroundColor: '#f44336',
                                    color: 'white',
                                    }}
                                    circle
                                    size="xs"
                                    onClick={handleRemoveFile}
                                />
                                </div>
                            }
                            {
                                !preview && <div style={{ textAlign: "right" }}>
                                    <Uploader
                                        action=""
                                        autoUpload={false}
                                        fileListVisible={false}
                                        accept="image/*"
                                        onChange={handleFileChange}
                                        disabled={uploading}
                                        cleanable={false}
                                        multiple={false}
                                    >
                                        <StandardButton type="button" disabled={uploading}>
                                            {'Upload'}
                                        </StandardButton>
                                    </Uploader>
                                </div>
                            }
                            </Panel>
                        </Col>
                    </Row>
                </Grid>
                <div style={{ textAlign: "right", margin: "10px" }}>
                    <FormGroup>
                        <Button type="submit" appearance="primary" color="violet">Save client</Button>
                    </FormGroup>
                </div>
            </Form>
        </Panel>
    )
}

export default CreateClient;