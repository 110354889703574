import { Accordion, Container, SelectPicker } from "rsuite";
import Drivers from "./Drivers";
import AISettings from "./AI";

const ConfigTracker = () => {
    return (
        <Container style={{ margin: "20px 100px"}}>
            <div style={{ margin: "20px 0", textAlign: "right"}}>
                <SelectPicker searchable={false} cleanable={false} defaultValue={0} data={[{key:0, label: 'Select project', value: 0}]} style={{ width: "300" }}/>
            </div>
            <Accordion bordered defaultActiveKey={1}>
                <Accordion.Panel header="TRACKER settings" eventKey={1}></Accordion.Panel>
                <Accordion.Panel header="Select drivers" eventKey={2}><Drivers/></Accordion.Panel>
                <Accordion.Panel header="Time series data prep" eventKey={3}></Accordion.Panel>
                <Accordion.Panel header="Predict settings" eventKey={4}></Accordion.Panel>
                <Accordion.Panel header="Custom report settings" eventKey={5}></Accordion.Panel>
                <Accordion.Panel header="AI+ settings" eventKey={6}><AISettings/></Accordion.Panel>
                <Accordion.Panel header="KPI+ settings" eventKey={7}></Accordion.Panel>
                <Accordion.Panel header="Segment+ settings" eventKey={8}></Accordion.Panel>
            </Accordion>
        </Container>
    )
}

export default ConfigTracker;