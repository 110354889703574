import { css, Global } from '@emotion/react/macro';

export const DocumentScreenBehaviour = css`
  width: 100vw;
  max-width: 100vh;
  align-items: center;
  justify-content: center;
`;

export const DocumentBelowNavbarBehaviour = css`
  padding-left: 40px;
  padding-right:40px;
`;

