import React from 'react';
import { Text } from 'rsuite';

/**
 * InfoBoxLong component
 * @param {string} infoLabel - Label text on the left
 * @param {string} infoValue - Value text on the right (bold)
 */
const InfoBoxLong = ({ infoLabel, infoValue }) => {
    return (
        <>
            <div style={{
                backgroundColor: '#f4ebff', // Same color as CapiaAccordion header
                padding: '20px 20px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderRadius: '6px',
                marginBottom: '10px'
            }}>
                <Text style={{margin:'0px 5px 0px 0px'}}>{infoLabel}</Text>
                <Text style={{ fontWeight: 'bold' , margin:'0px 0px 0px 5px' }}>{infoValue}</Text>
            </div>
        </>
    );
};

export default InfoBoxLong;