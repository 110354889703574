import { useNavigate } from "react-router-dom";
import { Button, Container, Panel, Stack, Text } from "rsuite";

const ModelDashboard = ()=>{
    const navigate = useNavigate();
    return (
        <Container style={{ margin: "20px 0" }}>
            <Panel style={{ margin: "10px" }} bordered>
                <Stack justifyContent="space-between">
                    <span><Text weight="bold" size="xxl" transform="uppercase">Tracker</Text></span>
                    <Button appearance="primary" color="violet" onClick={()=>navigate("/models/new/tracker")}>Build model</Button>
                </Stack>
            </Panel>
            <Panel style={{ margin: "10px" }} bordered>
                <Stack justifyContent="space-between">
                    <span><Text weight="bold" size="xxl" transform="uppercase">ROMI</Text></span>
                    <Button appearance="primary" color="violet" onClick={()=>navigate("/models/new/romi")}>Build model</Button>
                </Stack>
            </Panel>
            <Panel style={{ margin: "10px" }} bordered>
                <Stack justifyContent="space-between">
                    <span><Text weight="bold" size="xxl" transform="uppercase">Scoring</Text></span>
                    <Button appearance="primary" color="violet" disabled>Build model</Button>
                </Stack>
            </Panel>
            <Panel style={{ margin: "10px" }} bordered>
                <Stack justifyContent="space-between">
                    <span><Text weight="bold" size="xxl" transform="uppercase">Traffic</Text></span>
                    <Button appearance="primary" color="violet" disabled>Build model</Button>
                </Stack>
            </Panel>
            <Panel style={{ margin: "10px" }} bordered>
                <Stack justifyContent="space-between">
                    <span><Text weight="bold" size="xxl" transform="uppercase">Sentiment</Text></span>
                    <Button appearance="primary" color="violet" disabled>Build model</Button>
                </Stack>
            </Panel>
        </Container>
    )
}
export default ModelDashboard;